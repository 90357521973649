import React, { useEffect, useState } from "react";
import { StyleSheet, ScrollView, ActivityIndicator, View } from "react-native";
import { SearchBar, Text } from "react-native-elements";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { NegociosReportadosLista } from "../../../componentes/Cuenta/NegociosReportados/NegociosReportadosLista";



export function NegociosReportados() {
  const [reportes, setReportes] = useState(null);
  const [filterReportes, setFilterReportes] = useState(reportes);
  const [texto, setTexto] = useState("");



 
  useEffect(() => {
    const q = query(
        collection(db, "reportes"),
        orderBy("createdAt", "desc"),
            );
        onSnapshot(q, (snapshot) => {
            setReportes(snapshot.docs);
            setFilterReportes(snapshot.docs);
         });
         return () => {
            setReportes(null);
            setFilterReportes(null);
               };
         }, []);
   
          const searchFilterPromociones = (text) => {
                  if (text) {
                    const newData = reportes.filter((item) => {
                    const reporte = item.data();
                    const nombre = reporte.nombre
                
                    const search = nombre
                    const itemData = search ? 
                                     search.toUpperCase() : ''.toUpperCase();
                    const textData = text.toUpperCase();
                    return itemData.indexOf(textData) > -1;                               
                        });
                        setFilterReportes(newData);
                        setTexto(text);
               }  else {
                setFilterReportes(reportes);
                 setTexto(text);
                  }
                }
           

      if (!reportes) {
        return (
          <View style={{flex: 1, 
                        alignItems: "center",
                        justifyContent: "center",
                        }}>
          <ActivityIndicator  size="large" color="#ff850c" />
          <Text style={{fontSize: 15,
                        color: "#00aeb7", 
                        textTransform: "uppercase",
                        marginBottom: 10, 
                        marginTop: 5}}>Cargando</Text>
      </View>
        );
      };



    return(
    <ScrollView >
       <SearchBar 
           placeholder='Busca la empresa'
           platform="default"
           value={texto}
          placeholderTextColor={"#00aeb7"}
          containerStyle={{backgroundColor: "#00aeb7", marginTop: 5}}
          inputContainerStyle={{backgroundColor: "white"}}
          onChangeText={(text) => searchFilterPromociones(text)}
        />
         <NegociosReportadosLista filterReportes={filterReportes} />
        </ScrollView>
    )
}


export const styles = StyleSheet.create({
    content: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
    }, 
    input: {
        width: "100%",
        marginTop: 20,
    },
    icon: {
        color: "#c1c1c1",
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
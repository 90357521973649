import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, Image } from "react-native-elements";
import { query, collection, orderBy, limit, where, onSnapshot} from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { db, screenName } from "../../../utils";
import { map, size } from 'lodash';


export function BellezayModa(props) {
    const { hash } = props;
    const [favoritosCercanos, setFavoritosCercanos] = useState(null);
    const [favoritosTodos, setFavoritosTodos] = useState(null);
    const navegacion = useNavigation();


    useEffect(() => {
      if (hash) {
        const q = query(
          collection(db, "negocios"),
          where("giroNegocio", "==", "bellezaymoda"),
          where("geohash","==", hash),
          orderBy("ratingMedia", "desc"),
          limit(25)
         );
   onSnapshot(q, (item) => {
      const docs = item.docs
       setFavoritosCercanos(docs);
           });
           return () => {
            setFavoritosCercanos(null);
            }; 
          };
     },[hash]);

    useEffect(() => {
        const q = query(
          collection(db, "negocios"),
          where("giroNegocio", "==", "bellezaymoda"),
          orderBy("ratingMedia", "desc"),
          limit(25)
         );
   onSnapshot(q, (item) => {
      const docs = item.docs
       setFavoritosTodos(docs);
       });
       return () => {
        setFavoritosTodos(null);
       };
    },[hash]);


    const irNegocio = (id) => {
      navegacion.navigate(screenName.promociones.negocio, { id: id } )
  };




  if (size(favoritosCercanos) === 0) {
      return(
        map(favoritosTodos, (item, index) => {
              const negocio = item.data()   
            return (
               <TouchableOpacity key={index} 
                                 style={styles.promocion} 
                                 onPress={() => irNegocio(negocio.id)} >
                <Image style={styles.imagen} 
                       source={{uri: negocio.imgNegocio}} />
                <Text style={styles.nombre}>{negocio.nombre}</Text>
                <Text style={styles.descripcion}>{negocio.descripcion}</Text>
             </TouchableOpacity>
                  );
                })
              );
     } else {
      return( 
        map(favoritosCercanos, (item, index) => {
          const negocio = item.data();
          return (
            <TouchableOpacity key={index} 
                              style={styles.promocion} 
                              onPress={() => irNegocio(negocio.id)} >
                <Image style={styles.imagen} 
                       source={{uri: negocio.imgNegocio}} />
                <Text style={styles.nombre}>{negocio.nombre}</Text>
                <Text style={styles.descripcion}>{negocio.descripcion}</Text>
            </TouchableOpacity>
          );
        })
     )}
  };





  const styles = StyleSheet.create({
    content: {
       alignItems: "center",
         },
    promocion: {
        alignItems: "center",
        marginHorizontal: 3,
        marginBottom: 5,
        width: 250,
    },     
    imagen: {
        resizeMode: "contain",
        height: 200,
        width: 250,
        borderRadius: 5,
    },
    nombre: {
       alignSelf: "flex-start",
        fontSize: 18,
        fontWeight: "700",
        color: "#00aeb7",
        width: 200,
    },
    descripcion: {
        alignSelf: "flex-start",
        fontSize: 13,
        width: 200,
       }
});
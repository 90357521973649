import React, { useState } from 'react';
import { View } from "react-native";
import { Button, Image, Input} from "react-native-elements";
import { useFormik } from "formik";
import { getAuth, updateEmail, EmailAuthProvider, reauthenticateWithCredential} from "firebase/auth";
import { initialValues, validationSchema} from "./CambiarEmail.Datos";
import { styles } from "./CambiarEmailForm.styles";


export function CambiarEmailForm(props) {
    const { close, onReload } = props;
    const [verContrasena, setVerContrasena] = useState(false);

    const showPassword = () => setVerContrasena((prevState) => !prevState ); 

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue)=> {
        try {
          const currentUser = getAuth().currentUser;
          const credentials = EmailAuthProvider.credential(
            currentUser.email,
            formValue.contrasena);
           await  reauthenticateWithCredential(currentUser, credentials);
           await updateEmail(currentUser, formValue.email);
            onReload();
            close();
        } catch (error) {
          alert("Error al cambiar email de usuario, intente mas tarde.")
        }
        }
    })

  return (
     <View style={styles.content} >
      <View>
      <Image source={require("../../../../assets/img/finddin-logo.png")} 
             style={{resizeMode: "contain", width: 200, height: 50, marginBottom: 20}} 
             />
      </View>
        <Input placeholder='Nuevo email' 
        containerStyle={styles.input} 
        onChangeText={(text) => formik.setFieldValue("email", text)}
        errorMessage={formik.errors.email}
        />
        <Input 
              placeholder='Contraseña'
              secureTextEntry={verContrasena ? false : true}
              containerStyle={styles.input}
              rightIcon={{
                 type: "material-community",
                 name: verContrasena ? "eye-off-outline" : "eye-outline",
                 color: "#c2c2c2",
               onPress: showPassword,
                 }}
                 onChangeText={(text) => formik.setFieldValue("contrasena", text) } 
                 errorMessage={formik.errors.contrasena}
                />
  <Button title="Cambiar email" 
          containerStyle={styles.btnContainer} 
          buttonStyle={styles.btn} 
          onPress={formik.handleSubmit}
          loading={formik.isSubmitting}
          />
</View>
     )
};

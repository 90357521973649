import React, { useEffect, useState} from 'react';
import { ScrollView } from "react-native";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { map, size } from "lodash";
import { SinFavoritos } from "../../componentes/Favoritos/SinFavoritos/SinFavoritos";
import { NegociosFavoritos } from "../../componentes/Favoritos/NegociosFavoritos/NegociosFavoritos";
import { Loading } from "../../componentes/Shared/Loading/Loading";
import { db } from "../../utils/firebase";

export function FavoritosScreen() {
  const [userLogeado, setUserLogeado] = useState(null);
  const [negocios, setNegocios] = useState(null);
  const auth = getAuth();


     useEffect(() => {
      onAuthStateChanged(auth, (user) => {
            setUserLogeado(user ? true : false);
           });
       return () => {
        setUserLogeado(null);
       };
       },[]);


  useEffect(() => {
    if (userLogeado) {
      const q = query(
        collection(db, "favoritos"),
        where("idUser", "==", auth.currentUser.uid)
         );
      onSnapshot(q, async (snapshot) => {
       let arrayNegocios = [];
       for await (const item of snapshot.docs) {
           const data = item.data();
           const docRef = doc(db, "negocios", data.idNegocio);
           const docSnap = await getDoc(docRef);
            const newData = docSnap.data();
                 newData.idFavorito = data.id;
                 arrayNegocios.push(newData);
           }
           setNegocios(arrayNegocios);    
          });
        };
     return () => {
      setNegocios(null);
     };
   },[userLogeado]);

  
   if (!negocios && userLogeado === null) return <Loading  show text="Cargando" />
   if (size(negocios) === 0) return <SinFavoritos />

  return (
    <ScrollView contentContainerStyle={{ flexWrap: "wrap", flexDirection: "row" }} >
       {map(negocios, (negocio) => (
        <NegociosFavoritos key={negocio.id} negocio={negocio} />
    ))}
     </ScrollView>
  );
};
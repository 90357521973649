import { StyleSheet } from "react-native";




export const styles = StyleSheet.create({
    eliminarEvento: {
        marginTop: 6,
        padding: 5,
        borderRadius: 3,
        backgroundColor: "red",
        color: "#ffffff",
    },
    descripcion: {
          marginTop: 10,
          fontSize: 18,
          padding: 10,
          backgroundColor: "#ff850c",
          color: "#ffffff",
          borderRadius: 10,
    },
    logoForm: {
        resizeMode: "contain",
        width: "100%",
        height: 100,
        marginTop: 0, 
        marginBottom: 5,
    },
    containerIcon: {
        justifyContent: "center",
        marginRight: 10,
        backgroundColor: "#e3e3e3",
        width: 120,
        height: 120,
    },
    botonPromoForm: {
        backgroundColor: "#ff850c",
        marginTop: 10,
        marginBottom: 10,
    },
    imagenPromocion: {
        width: 120,
        height: 120,
        marginRight: 10,
        backgroundColor: "#e3e3e3",
        position: "absolute",
    },
    viewImagenesEventos: {
        marginTop: 10,
    },
    error: {
        marginHorizontal: 20,
        marginTop: 10,
        color: "#ff0000",
        fontSize: 12,
        paddingLeft: 6,
   },
   eventoContainer: {
    alignItems: "center",
    width: 500,
    margin: 5,
    padding: 10,
    borderRadius:5,
    borderWidth: 0.5,
    borderColor: "#ff850c",

},     
imagenEvento: {
    resizeMode: "cover",
    height: 400,
    width: 480,
},
});
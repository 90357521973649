import React, { useEffect, useState, ChangeEvent } from 'react';
import { View } from "react-native";
import { Image, Text } from 'react-native-elements';
import { loadStripe } from "@stripe/stripe-js";
import { getAuth } from "firebase/auth";
import { doc, setDoc, collection, onSnapshot, query, where } from "firebase/firestore"; 
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as Linking from 'expo-linking';
import { db } from "../../../../utils/firebase";
import { map, indexOf } from "lodash";

 const stripePromise = loadStripe("pk_live_51LpA83H3WCUp4hYkYNTltYoFiD33dk7T7bll9SY9EhswvLzHddWFoRO6LcbCPlqFI4sJgwKW5pU1BNtqCHrYeVAX00ruTnOwVA");
 

export function PagoMensual(){
 const {uid, email, displayName } = getAuth().currentUser;
 const [codigosPromociones, setCodigosPromociones] = useState(null);
 const [idSubscripcion, setIdSubscripcion] = useState(null);
 const [arrayCodigos, setArrayCodigos] = useState([]);
 const [usuariosIlimitados, setUsuariosIlimitados] = useState(null);
 const [inputText, setInputText] = useState("");
 const codPromo = indexOf(arrayCodigos,inputText,0);
 const auth = getAuth();
 const userId = auth.currentUser.uid;
 const indexIlimitados = indexOf(usuariosIlimitados,userId,0);

 console.log(indexIlimitados);


useEffect(() => {
    let ids = [];
        const q = query(
          collection(db, "usuariosilimitados"),
         );
             onSnapshot(q, (snapshot) => {
              const docs = snapshot.docs;
              map(docs, (doc) => {
              const data = doc.data();
              const id = data.id;
              ids.push(id);
              })
              setUsuariosIlimitados(ids);  
             });
        return () => {
            setUsuariosIlimitados(null);
           };
          },[]);


useEffect(() => {
    const q = query(
       collection(db, "subscripciones"),
       where("idUser", "==", userId),
    );
   onSnapshot(q, (snapshot) => {
       const docs = snapshot.docs;
         map(docs, (doc) => {
             const datos = doc.data();
             const id = datos.id;
             setIdSubscripcion(id);
       })
       });
       return () => {
           setIdSubscripcion(null);
       }
  },[]);


map(codigosPromociones, (item) => {
    const obj = item.data();
    const text = obj.texto;
    arrayCodigos.push(text);
})




 const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const getSubscription100 = async (e) => {
        e.preventDefault();
        e.currentTarget.disabled = true;

        try {
            const paymentMethod = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
            });

            if (paymentMethod.error) {
                alert("Ingrese datos de tarjeta completos");
            } else {
                const datosUser = { displayName, email };
   
                const respuesta = await fetch( `https://us-central1-finddin9.cloudfunctions.net/subscripcion/suscripcion-99`, {
                        method: "POST",
                        headers: {
                        "Content-Type": "application/json",
                         },
                         body: JSON.stringify(datosUser)
                      });                      
                     const {subscriptionId, clientSecret, error} = await respuesta.json();

                     if (error) {
                        alert("No se pudo realizar correctamente la suscripción, intente mas tarde.");
                      } else {
                        const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
                            payment_method: {
                                card: elements.getElement(CardElement),
                            }
                        });
         
                        if (confirmPayment.error) {
                             alert("Error al realizar cobro, revise la tarjeta con su banco o intente mas tarde.");
                            } else {
                            const fecha = new Date();
                            const id = subscriptionId;
                            const data = {
                                id: subscriptionId,
                                idUser: uid,
                                fecha: fecha
                            }
                            await setDoc(doc(db, "subscripciones", id), data);
                            alert(`${displayName || "Anonimo"} tu donacion se ha realizado correctamnete.`);
                        }
                    }
            }
        } catch (error) {
            alert("No se pudo realizar la suscripción, intente mas tarde.");
        }
    }


    

    const getSubscriptionPromo = async (e) => {
        e.preventDefault();
        e.currentTarget.disabled = true;

        try {
            const paymentMethod = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
            });

            if (paymentMethod.error) {
                alert("Ingrese datos de tarjeta completos");
            } else {
                const datosUser = { displayName, email };
   
                const respuesta = await fetch( `https://us-central1-finddin9.cloudfunctions.net/subscripcion/suscripcion-49`, {
                        method: "POST",
                        headers: {
                        "Content-Type": "application/json",
                         },
                         body: JSON.stringify(datosUser)
                      });                      
                     const {subscriptionId, clientSecret, error} = await respuesta.json();

                     if (error) {
                        alert("No se pudo realizar correctamente la suscripción, intente mas tarde.");
                      } else {
                        const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
                            payment_method: {
                                card: elements.getElement(CardElement),
                            }
                        });
         
                        if (confirmPayment.error) {
                             alert("Error al realizar cobro, revise la tarjeta con su banco o intente mas tarde.");
                            } else {
                            const id = subscriptionId;
                            const data = {
                                id: subscriptionId,
                                idUser: uid
                            }
                            await setDoc(doc(db, "subscripciones", id), data);
                            alert(`${displayName || "Anonimo"} tu donacion se ha realizado correctamnete.`);
                        }
                    }
            }
        } catch (error) {
            alert("No se pudo realizar la suscripción, intente mas tarde.");
        }
    }



    return (
        <div style={{alignContent: "center", alignItems: "center", margin: 10}} >
            <Image source={require("../../../../../assets/img/finddin-logo.png")} 
                       style={{ width:300, height: 65, marginBottom: 15 }} />
             <Text style={{fontSize: 20, color: "#00aeb7"}} >Para subir tus negocios suscribete a Finddin, la suscripción es mensual y sin plazo forzoso.</Text>
           <View style={{marginTop: 25}} >
           <CardElement />
           </View>
           <View style={{alignSelf: "center", marginTop: 25}} >
           <button onClick={(event) => {
                  getSubscription100(event);
            }} 
            style={{alignSelf: "center", 
                    height: 50, 
                    width: 250,
                    backgroundColor: "#FFFFFF",
                    borderColor: "#ff850c",
                    borderWidth: 5,
                    fontSize: 18,
                marginBottom: 10 }}
                 >$99.00
        </button>
        <button onClick={(event) => {
                  getSubscriptionPromo(event);
            }} 
            style={{alignSelf: "center", 
                    height: 50, 
                    width: 250,
                    backgroundColor: "#FFFFFF",
                    borderColor: "#ff850c",
                    borderWidth: 5,
                    fontSize: 18 }}
                  >Promoción $49.00
        </button>
            </View>
         </div>
       )
 }; 

 if (indexIlimitados !== -1) {
    return (
        <View style={{alignItems: "center", alignContent: "center", marginVertical: 5}}  >
        <Image source={require("../../../../../assets/img/finddin-logo.png")} 
               style={{width:300, height: 65, margin: 5}} />
        <Text style={{fontSize: 20, color: "#00aeb7", marginTop: 15 }} >
            Ya puedes agregar y administrar tus negocios desde la aplicación de Finddin.
        </Text>
          <Image source={require("../../../../../assets/img/app-google-store.jpg")} 
               style={{width: 180, height: 130, margin: 5, marginTop: 10}} />
          <Text style={{fontSize: 22, fontWeight: "600", color: "#ff850c", marginTop: 15, textDecorationLine: "underline" }}
                onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.finddin.androidfinal")}
           >
            Descarga para Android.
          </Text>
          <Text style={{fontSize: 22, fontWeight: "600", color: "#ff850c", marginTop: 15, textDecorationLine: "underline" }}
                onPress={() => Linking.openURL("https://apps.apple.com/mx/app/finddin/id6450434173")}
          >
            Descarga para iPhone.
          </Text>
    </View>
      );
 }    


 

   if (!idSubscripcion) {
    return(
        <Elements stripe={stripePromise}  >
        <CheckoutForm />
        </Elements>
        )
    } else {
        return(
            <View style={{alignItems: "center", alignContent: "center", marginVertical: 5}}  >
                <Image source={require("../../../../../assets/img/finddin-logo.png")} 
                       style={{width:300, height: 65, margin: 5}} />
                <Text style={{fontSize: 20, color: "#00aeb7", marginTop: 15 }} >
                    Ya puedes agregar y administrar tus negocios desde la aplicación de Finddin.
                </Text>
                  <Image source={require("../../../../../assets/img/app-google-store.jpg")} 
                       style={{width: 180, height: 130, margin: 5, marginTop: 10}} />
                  <Text style={{fontSize: 22, fontWeight: "600", color: "#ff850c", marginTop: 15, textDecorationLine: "underline" }}
                        onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.finddin.androidfinal")}
                   >
                    Descarga para Android.
                  </Text>
                  <Text style={{fontSize: 22, fontWeight: "600", color: "#ff850c", marginTop: 15, textDecorationLine: "underline" }}
                        onPress={() => Linking.openURL("https://apps.apple.com/mx/app/finddin/id6450434173")}
                  >
                    Descarga para iPhone.
                  </Text>
            </View>
        )
    }
    
}


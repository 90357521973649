import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Avatar, ListItem, Text, SearchBar } from 'react-native-elements';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { query, collection, where, onSnapshot, orderBy } from "firebase/firestore";
import { map, size } from 'lodash';
import { db } from "../../../utils/firebase";
import { Loading } from "../../Shared/Loading/Loading";
import { remove } from "remove-accents";
import { styles } from "./Promociones.styles";


export function Promociones(props) {
  const { negocio } = props;
  const [logeado, setLogeado] = useState(null);
  const [promociones, setPromociones] = useState(null);
  const [loading2, setLoading2] = useState(false);
  const [texto, setTexto] = useState("");
  const [filterPromociones, setFilterPromociones] = useState(promociones);
  const [expanded, setExpanded] = useState(false);
  const auth = getAuth();
  

 useEffect(() => {
  onAuthStateChanged(auth, (user) => {
    setLogeado(user);
         });
     return () => {
      setLogeado(null);
       };
        },[]);

 useEffect(() =>{
    setLoading2(false);
    const q = query(
          collection(db, "promociones"),
          where("idNegocio", "==", negocio.id),
          orderBy("createdAt", "desc"),
          );
         onSnapshot(q, (snapshot) => {
          const docs = snapshot.docs;
          map(docs, (doc) => {
            const data = doc.data();
            setPromociones(data.promociones);
            setFilterPromociones(data.promociones);
          })
          setLoading2(true);
          setExpanded(true);
         });
         return () => {
          setPromociones(null);
          setExpanded(false);
         };
      },[]);


           const searchFilterPromociones = (text) => {
            setExpanded(true);
            if (text) {
              const newData = promociones.filter((item) => {
              const promocion = item;
              const descripcion = remove(promocion.descripcionPromocion);
              const search = descripcion
              const itemData = search ? 
                               search.toUpperCase() : ''.toUpperCase();
              const textData = text.toUpperCase().trim();
              return itemData.indexOf(textData) > -1;                               
                  });
                  setFilterPromociones(newData);
                  setTexto(text);
         }  else {
          setExpanded(false);
           setFilterPromociones(promociones);
           setTexto(text);
            }
      };


  if (!loading2) return <Loading show text="Cargando" />

  return (
      <View style={styles.content}>
        <Text style={styles.titulo}>Promociones</Text>
        <SearchBar 
       placeholder='Busca la promocion'
       platform="default"
       value={texto}
       placeholderTextColor={"#00aeb7"}
       containerStyle={{backgroundColor: "#00aeb7"}}
       inputContainerStyle={{backgroundColor: "white"}}
       onChangeText={(text) => searchFilterPromociones(remove(text))}
        />
        {size(promociones) === 0 ? (
          <View style={styles.contentSinPromociones}>
             <Text style={styles.textSinPromocion}>Este negocio no tiene promociones</Text>
          </View>
        ) : (
       <ListItem.Accordion
          content={
           <>
           <Avatar source={{uri: promociones[0].imgPromocion}} 
                   size={100}
                   rounded
                   />
             <ListItem.Content>
               <ListItem.Title style={{ color: "#00aeb7", 
                               fontSize: 18, 
                               marginLeft: 15}}
                               >{ promociones[0].descripcionPromocion} 
              </ListItem.Title>
             </ListItem.Content>
           </>
         }
         isExpanded={expanded}
         onPress={() => {
           setExpanded(!expanded);
         }}
          >
      {map(filterPromociones, (promocion, index) => (
         <ListItem key={index} bottomDivider>
             <Avatar  source={{ uri: promocion.imgPromocion }}
                      size={90} 
                      rounded
                    />
         <ListItem.Content>
           <ListItem.Title style={{ color: "#ff850c" }} >{promocion.descripcionPromocion}</ListItem.Title>
         </ListItem.Content>
       </ListItem>
           ))}
       </ListItem.Accordion>
         )}
      </View>
  );
};

import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    logo: {
      resizeMode: "contain",
      width: "100%",
      height: "80%",
      marginTop: 0,
    },
    content: {
        flex: 1,
        marginHorizontal: 15,
        justifyContent: "center",
    },
    ratingContent: {
        height: 160,
    },
    comentario: {
        height: 150,
        marginTop: 50,

    },
    btnContainer: {
        margin: 20,
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
import React from 'react';
import { ScrollView, Text } from 'react-native';



export function TerminosyCondiciones() {
   


  return (
    <ScrollView>
<Text>Información general.</Text>          
<Text>
Esta aplicación móvil es operada por Bruno Haros Garcia Travesi con nombre comercial Finddin.
</Text>
<Text>
En toda la aplicación móvil, los términos “nosotros”, “nos” y “nuestro” se refieren a Bruno Haros Garcia Travesi con nombre comercial Finddin 
y el termino “app” y “Finddin” y  se refiere a la aplicación móvil Finddin, incluyendo toda la información,
herramientas y servicios disponibles para ti en esta aplicación móvil, el usuario, 
está condicionado a la aceptación de todos los términos, condiciones, políticas y notificaciones aquí establecidos.
</Text>
<Text>
Al utilizar Finddin, participas en nuestro “Servicio” y aceptas los siguientes términos y condiciones (“Términos de Servicio”, “Términos”),
incluidos todos los términos y condiciones adicionales y las políticas a las que se hace referencia en el presente documento 
y/o disponible a través de hipervínculos. Estas Condiciones de Servicio se aplican a todos los usuarios de Finddin, 
incluyendo sin limitación a usuarios que sean navegadores, proveedores, clientes, comerciantes, y/o colaboradores de contenido.
</Text>
<Text>
Por favor, lee estos Términos de Servicio cuidadosamente antes de acceder o utilizar Finddin. 
Al acceder o utilizar cualquier parte de la app, estás aceptando los Términos de Servicio. 
Si no estás de acuerdo con todos los términos y condiciones de este acuerdo, entonces no deberías utilizar la app o usar 
cualquiera de los servicios. Si los Términos de Servicio son considerados una oferta, 
la aceptación está expresamente limitada a estos Términos de Servicio.
</Text>
<Text>
Cualquier función nueva o herramienta que se añadan a la app actual, también estarán sujetas a los Términos de Servicio. 
Puedes revisar la versión actualizada de los Términos de Servicio, en cualquier momento en esta página. 
Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los Términos de Servicio mediante la publicación 
de actualizaciones y/o cambios en nuestra app. 
Es tu responsabilidad chequear esta página periódicamente para verificar cambios. 
Tu uso continuo o el acceso a la app después de la publicación de cualquier cambio constituye la aceptación de dichos cambios.
</Text>
<Text>Sección 1.- Términos de la app.</Text>
<Text>
Al utilizar Finddin, declaras que tienes al menos la mayoría de edad en tu estado o provincia de residencia, 
o que tienes la mayoría de edad en tu estado o provincia de residencia y que nos has dado tu consentimiento para permitir 
que cualquiera de tus dependientes menores use este sitio.
No puedes usar nuestros servicios con ningún propósito ilegal o no autorizado, tampoco puedes, en el uso del Servicio, 
violar cualquier ley en tu jurisdicción (incluyendo pero no limitado a las leyes de derecho de autor).
No debes transmitir gusanos, virus o cualquier código de naturaleza destructiva.
El incumplimiento o violación de cualquiera de estos Términos darán lugar al cese inmediato de tus Servicios.
</Text>
<Text>Sección 2.- Condiciones generales.</Text>
<Text>
Nos reservamos el derecho de rechazar la prestación de servicio a cualquier persona, por cualquier motivo y en cualquier momento.
Entiendes que tu contenido (sin incluir la información de tu tarjeta de crédito), 
puede ser transferida sin encriptar e involucrar (a) transmisiones a través de varias redes; y 
(b) cambios para ajustarse o adaptarse a los requisitos técnicos de conexión de redes o dispositivos. 
La información de tarjetas de crédito está siempre encriptada durante la transferencia a través de las redes.
Estás de acuerdo con no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Servicio, uso del Servicio, 
o acceso al Servicio o cualquier contacto en la app a través del cual se presta el servicio, 
sin el expreso permiso por escrito de nuestra parte.
Los títulos utilizados en este acuerdo se incluyen solo por conveniencia y no limita o afecta a estos Términos.
</Text>
<Text>Sección 3.- Exactitud, exhaustividad y actualidad de la información.</Text>
<Text>
No nos hacemos responsables si la información disponible en este sitio no es exacta, completa o actual. 
El material en esta app es provisto sólo para información general y no debe confiarse en ella o utilizarse como la única base 
para la toma de decisiones sin consultar primeramente, información más precisa, completa u oportuna. 
Cualquier dependencia en el materia de este sitio es bajo su propio riesgo.
Este sitio puede contener cierta información histórica. La información histórica, 
no es necesariamente actual y es provista únicamente para tu referencia. 
Los usuarios se reservan el derecho de modificar los contenidos de esta app en cualquier momento, 
pero no tienen obligación de actualizar cualquier información en nuestro sitio. 
Aceptas que es tu responsabilidad de monitorear los cambios en nuestro sitio.
</Text>
<Text>Sección 4.- Donaciones.</Text>
<Text>
Los montos monetarios de las donaciones están sujetos a cambio sin aviso.
Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte del contenido) en cualquier momento sin aviso previo.
No seremos responsables ante ti o alguna tercera parte por cualquier modificación, cambio de monto monetario, 
suspensión o discontinuidad del Servicio.
La donación puede ser cancelada después de 24 horas.
No realizamos reembolsos de las donaciones previamente cargadas a tu tarjeta.
</Text>
<Text>Sección 5.- Herramientas opcionales.</Text>
<Text>
Es posible que te proporcionamos acceso a herramientas de terceros a los cuales no monitoreamos y 
sobre los que no tenemos control ni entrada.
Reconoces y aceptas que proporcionamos acceso a este tipo de herramientas «tal cual» y «según disponibilidad» sin garantías, 
representaciones o condiciones de ningún tipo y sin ningún respaldo. 
No tendremos responsabilidad alguna derivada de o relacionada con tu uso de herramientas proporcionadas por terceras partes.
Cualquier uso que hagas de las herramientas opcionales que se ofrecen a través del sitio bajo tu propio riesgo y discreción y 
debes asegurarte de estar familiarizado y aprobar los términos bajo los cuales estas herramientas son proporcionadas por el o 
los proveedores de terceros.
También es posible que, en el futuro, te ofrezcamos nuevos servicios y/o características a través de la 
app(incluyendo el lanzamiento de nuevas herramientas y recursos). 
Estas nuevas características y/o servicios también estarán sujetos a estos Términos de Servicio.
</Text>
<Text>Sección 6.- Enlaces de terceras partes.</Text>
<Text>
Cierto contenido, productos y servicios disponibles vía nuestro Servicio puede incluir material de terceras partes.
Enlaces de terceras partes en este sitio pueden redireccionarse a sitios web de terceras partes que no están afiliadas con nosotros. 
No nos responsabilizamos de examinar o evaluar el contenido o exactitud y no garantizamos ni tendremos ninguna obligación o 
responsabilidad por cualquier material de terceros o sitios web, o de cualquier material, productos o servicios de terceros.
No nos hacemos responsables de cualquier daño o daños relacionados con la adquisición o utilización de bienes, 
servicios, recursos, contenidos, o cualquier otra transacción realizadas en conexión con sitios web de terceros. 
Por favor revisa cuidadosamente las políticas y prácticas de terceros y asegúrate de entenderlas antes de participar en 
cualquier transacción. Quejas, reclamos, inquietudes o preguntas con respecto a productos de terceros deben ser dirigidas 
a la tercera parte.
</Text>
<Text>Sección 7.- Comentarios de usuarios, captación y otros envíos.</Text>
<Text>
Si, a pedido nuestro, envías ciertas presentaciones específicas (por ejemplo la participación en concursos) o 
sin un pedido de nuestra parte envías ideas creativas, sugerencias, proposiciones, planes, u otros materiales, 
ya sea en línea, por email, por correo postal, o de otra manera (colectivamente, "comentarios"), aceptas que podamos, 
en cualquier momento, sin restricción, editar, copiar, publicar, distribuir, traducir o utilizar por cualquier medio comentarios 
que nos hayas enviado. No tenemos ni tendremos ninguna obligación (1) de mantener ningún comentario confidencialmente; 
(2) de pagar compensación por comentarios; o (3) de responder a comentarios.
Nosotros podemos, pero no tenemos obligación de, monitorear, editar o remover contenido que consideremos sea ilegítimo, 
ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno u objetable o viole la propiedad intelectual de 
cualquiera de las partes o los Términos de Servicio.
Aceptas que tus comentarios no violará los derechos de terceras partes, incluyendo derechos de autor, marca, privacidad, 
personalidad u otro derechos personal o de propiedad. Asimismo, aceptas que tus comentarios no contienen material difamatorio o ilegal, 
abusivo u obsceno, o contienen virus informáticos u otro malware que pudiera, de alguna manera, afectar el funcionamiento 
del Servicio o de cualquier sitio web relacionado. No puedes utilizar una dirección de correo electrónico falsa, 
usar otra identidad que no sea legítima, o engañar a terceras partes o a nosotros en cuanto al origen de tus comentarios. 
Tu eres el único responsable por los comentarios que haces y su precisión. No nos hacemos responsables y no asumimos ninguna 
obligación con respecto a los comentarios publicados por ti o cualquier tercer parte.
</Text>
<Text>Sección 8.- Información personal.</Text>
<Text>
Tu presentación de información personal a través de la app se rige por nuestra Política de Privacidad.
Para ver nuestro Aviso de Privacidad.
</Text>
<Text>Sección 9.- Errores, inexactitudes y omisiones.</Text>
<Text>
No asumimos ninguna obligación de actualizar, corregir o aclarar la información en el Servicio o en cualquier parte de la app, 
incluyendo, sin limitación, la información de precios, excepto cuando sea requerido por la ley.
</Text>
<Text>Sección 10.- Usos prohibidos.</Text>
<Text>
En adición a otras prohibiciones como se establece en los Términos de Servicio, se prohíbe el uso de la app o su contenido: 
(a) para ningún propósito ilegal; (b) para pedirle a otros que realicen o participen en actos ilícitos; 
(c) para violar cualquier regulación, reglas, leyes internacionales, federales, provinciales o estatales, u ordenanzas locales; 
(d) para infringir o violar el derecho de propiedad intelectual nuestro o de terceras partes; 
(e) para acosar, abusar, insultar, dañar, difamar, calumniar, desprestigiar, intimidar o discriminar por razones de género, 
orientación sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) para presentar información falsa o engañosa; 
(g) para cargar o transmitir virus o cualquier otro tipo de código malicioso que sea o pueda ser utilizado en cualquier forma 
que pueda comprometer la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, 
apps u otros sitios en Internet; (h) para recopilar o rastrear información personal de otros; 
(i) para generar spam, phish, pharm, pretext, spider, crawl, or scrape; (j) para cualquier propósito obsceno o inmoral; o 
(k) para interferir con o burlar los elementos de seguridad del Servicio o cualquier sitio web relacionado¿ otros sitios o Internet. 
Nos reservamos el derecho de suspender el uso del Servicio o de cualquier sitio web o app relacionado por violar cualquiera de los 
ítems de los usos prohibidos.
</Text>
<Text>Sección 11.- Exclusión de garantías; limitación de responsabilidad.</Text>
<Text>
No garantizamos ni aseguramos que el uso de nuestro servicio será ininterrumpido, puntual, seguro o libre de errores.
No garantizamos que los resultados que se puedan obtener del uso del servicio serán exactos o confiables.
Aceptas que de vez en cuando podemos quitar el servicio por períodos de tiempo indefinidos o cancelar el servicio en cualquier 
momento sin previo aviso.
Aceptas expresamente que el uso de, o la posibilidad de utilizar, el servicio es bajo tu propio riesgo. 
El servicio y todos los productos y servicios proporcionados a través del servicio son 
(salvo lo expresamente manifestado por nosotros) proporcionados «tal cual» y «según esté disponible» para su uso, sin ningún 
tipo de representación, garantías o condiciones de ningún tipo, ya sea expresa o implícita, incluidas todas las garantías o 
condiciones implícitas de comercialización, calidad comercializable, la aptitud para un propósito particular, durabilidad, 
título y no infracción.
En ningún caso Bruno Haros Garcia Travesi, nuestros directores, funcionarios, empleados, afiliados, agentes, 
contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier daño, 
pérdida, reclamo, o daños directos, indirectos, incidentales, punitivos, especiales o consecuentes de cualquier tipo, 
incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, 
costos de reemplazo, o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), 
responsabilidad estricta o de otra manera, como consecuencia del uso de cualquiera de los servicios o 
productos adquiridos mediante el servicio, o por cualquier otro reclamo relacionado de alguna manera con el uso del 
servicio o cualquier producto, incluyendo pero no limitado, a cualquier error u omisión en cualquier contenido, o 
cualquier pérdida o daño de cualquier tipo incurridos como resultados de la utilización del servicio o cualquier contenido 
(o producto) publicado, transmitido, o que se pongan a disposición a través del servicio, incluso si se avisa de su posibilidad. 
Debido a que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños 
consecuenciales o incidentales, en tales estados o jurisdicciones, nuestra responsabilidad se limitará en la 
medida máxima permitida por la ley.
</Text>
<Text>Sección 12.- Indemnización.</Text>
<Text>
Aceptas indemnizar, defender y mantener indemnizar a Bruno Haros Garcia Travesi y nuestras matrices, subsidiarias, 
afiliados, socios, funcionarios, directores, agentes, contratistas, concesionarios, proveedores de servicios, subcontratistas, 
proveedores, internos y empleados, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, 
hechos por cualquier tercero a causa o como resultado de tu incumplimiento de las Condiciones de Servicio o de los 
documentos que incorporan como referencia, o la violación de cualquier ley o de los derechos de un tercero.
</Text>
<Text>Sección 13.- Divisibilidad.</Text>
<Text>
En el caso de que se determine que cualquier disposición de estas Condiciones de Servicio sea ilegal, nula o inejecutable, 
dicha disposición será, no obstante, efectiva a obtener la máxima medida permitida por la ley aplicable, y la parte no exigible 
se considerará separada de estos Términos de Servicio, dicha determinación no afectará la validez de aplicabilidad de las 
demás disposiciones restantes.
</Text>
<Text>Sección 14.- Rescisión.</Text>
<Text>
Las obligaciones y responsabilidades de las partes que hayan incurrido con anterioridad a la fecha de terminación sobrevivirán a la 
terminación de este acuerdo a todos los efectos.
Estas Condiciones de servicio son efectivos a menos que y hasta que sea terminado por ti o nosotros. 
Puedes terminar estos Términos de Servicio en cualquier momento por avisarnos que ya no deseas utilizar nuestros servicios, 
o cuando dejes de usar nuestra app.
Si a nuestro juicio, fallas, o se sospecha que haz fallado, en el cumplimiento de cualquier término o disposición de estas 
Condiciones de Servicio, también podemos terminar este acuerdo en cualquier momento sin previo aviso, 
y seguirás siendo responsable de todos los montos adeudados hasta incluida la fecha de terminación; y/o 
en consecuencia podemos negar el acceso a nuestros servicios (o cualquier parte del mismo).
</Text>
<Text>Sección 15.- Acuerdo completo.</Text>
<Text>
Nuestra falla para ejercer o hacer valer cualquier derecho o disposición de estas Condiciones de Servicio no constituirá 
una renuncia a tal derecho o disposición.
Estas Condiciones del servicio y las políticas o reglas de operación publicadas por nosotros en esta app o con 
respecto al servicio constituyen el acuerdo completo y el entendimiento entre tú y nosotros y rigen el uso del Servicio y 
reemplaza cualquier acuerdo, comunicaciones y propuestas anteriores o contemporáneas, ya sea oral o escrita, 
entre tu y nosotros (incluyendo, pero no limitado a, cualquier versión previa de los Términos de Servicio).
Cualquier ambigüedad en la interpretación de estas Condiciones del servicio no se interpretarán en contra del grupo de redacción.
</Text>
<Text>Sección 16.- Ley.</Text>
<Text>
Para la interpretación y cumplimiento de los presentes términos y condiciones, 
las partes se someten a la jurisdicción de los tribunales de la Ciudad de renunciado expresamente a cualquier otro fuero 
que pudiere corresponderles por razón de sus domicilios presentes o futuros.
</Text>
<Text>Sección 17.- Cambios en los términos de servicio.</Text>
<Text>
Puedes revisar la versión más actualizada de los Términos de Servicio en cualquier momento en esta página.
Nos reservamos el derecho, a nuestra sola discreción, de actualizar, modificar o reemplazar cualquier parte de estas 
Condiciones del servicio mediante la publicación de las actualizaciones y los cambios en nuestra app. 
Es tu responsabilidad revisarlo en Finddin periódicamente para verificar los cambios. 
El uso continuo de o el acceso a nuestra app o el Servicio después de la publicación de cualquier cambio en estas 
Condiciones de servicio implica la aceptación de dichos cambios.
</Text>
<Text>Sección 18.- Información de contacto.</Text>
<Text>
Preguntas acerca de los Términos de Servicio deben ser enviadas al correo electrónico soporteglobal2000@gmail.com o 
por mensaje al 55 65350434.
</Text>
    </ScrollView>
  );
};

import React, {useEffect, useState} from 'react';
import { ActivityIndicator, Alert, View } from 'react-native';
import { Button, Overlay, Text } from 'react-native-elements'
import { query, collection, deleteDoc, doc, where, getDocs } from "firebase/firestore";
import { db } from '../../utils';

export function BotonEliminar(props) {
    const { negocio } = props;
    const [favoritos, setFavoritos] = useState(null);
    const [promociones, setPromociones] = useState(null);
    const [eventos, setEventos] = useState(null);
    const [loading, setLoading] = useState(false);

  
    useEffect(() => {
    if (negocio) {
       (async () => {
        const q = query(
          collection(db, "favoritos"),
          where("idNegocio", "==", negocio.id),
          );
    const querySnapshot = await getDocs(q);
              setFavoritos(querySnapshot);
      })();
      return () => {
        setFavoritos(null);
         };
       };
          },[negocio]); 


   useEffect(() => {
    if (negocio) {
       (async () => {
        const q = query(
           collection(db, "promociones"),
            where("idNegocio", "==", negocio.id),
              );
        const querySnapshot = await getDocs(q);
                 setPromociones(querySnapshot);
            })();
            return () => {
              setPromociones(null);
               };
                  };
               },[negocio]);

    useEffect(() => {
      if (negocio) {
         (async () => { 
           const q = query(
              collection(db, "eventos"),
              where("idNegocio", "==", negocio.id),
                         );
                   const querySnapshot = await getDocs(q);
                        setEventos(querySnapshot);
                      })();
                 return() => {
                   setEventos(null);
                        };
                    };
                    },[negocio]);

    

      const eliminarFavoritos = async () => {
             favoritos.forEach( async (item) => {
                  await deleteDoc(doc(db, "favoritos", item.id));
           });
       };
 
      const eliminarPromociones = async () => {
           promociones.forEach( async (item) => {
                await deleteDoc(doc(db, "promociones", item.id ));
              });
        };

        const eliminarEventos = async () => {
          eventos.forEach( async (item) => {
               await deleteDoc(doc(db, "eventos", item.id ));
             });
       }; 

     const eliminarNegocio = async () => {
          await deleteDoc(doc(db, "negocios", negocio.id));
        };

      const removerNegocio = () => {
        Alert.alert(
          "Eliminar Negocio",
          "Estas seguro que deseas eliminar el negocio?",
          [{
            text: "Cancel",
            style: "cancel",
          },
        {
           text: "Eliminar",
              onPress: async () => {
                try {
                  setLoading(true);
                  await eliminarFavoritos();
                  await eliminarPromociones();
                  await eliminarEventos();
                  await eliminarNegocio();
                  setLoading(false);
             } catch (error) {
              alert('No se ha podido eliminar el negocio, intente mas tarde.');
               } 
             }
           }
         ]
        ); 
      };
      

      
  return (
    <View>
      <Button
       title="Eliminar"
       buttonStyle={{backgroundColor: "#ff0000"}}
       onPress={() => {removerNegocio()}}
            />
      <Overlay isVisible={loading} overlayStyle={{borderColor:"#ff850c",
                                                  borderWidth: 3,
                                                  borderRadius: 2}} >
        <View>
        <ActivityIndicator size="large" 
                           color="#ff850c" 
                           style={{marginTop: 5}} />
        <Text style={{fontSize: 22, 
                      fontWeight:"normal", 
                      color:"#00aeb7", 
                      marginTop: 10, 
                      marginBottom:5}} >Eliminando Negocio...</Text>
        </View>
        </Overlay>  
    </View>
        );
    };

import React from "react";
import  { ListItem } from "react-native-elements";
import { map } from "lodash";
import { BotonEliminar } from "../BotonEliminar";


export function UsuariosTodosLista(props) {
    const { filterNegocios } = props;



    return (
        map(filterNegocios, (negocio, index) => {
            const data = negocio.data();
             return(
              <ListItem key={index} bottomDivider >
              <ListItem.Content>
                <ListItem.Title style={{color: "#0000FF"}} >{data.nombre}</ListItem.Title>
                <ListItem.Title>{data.idUser}</ListItem.Title>
                <ListItem.Title>{data.telefono}</ListItem.Title>
                <ListItem.Title>{data.ratingMedia}</ListItem.Title>
              </ListItem.Content>
              <BotonEliminar 
                    key={index} 
                    negocio={data}
                   />  
          </ListItem>
               );
            })
    )
}
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { UsuarioLogeadoScreen } from "./UsuarioLogeado/UsuarioLogeadoScreen";
import { IniciarSesion } from "./IniciarSesion/IniciarSesion";

export function CuentaScreen() {
    const [userLogeado, setUserLogeado] = useState(null);
    console.log(userLogeado);

    useEffect(() => {
        const auth = getAuth();
              onAuthStateChanged(auth, (user) => {
                 setUserLogeado(user ? true : false);
                 console.log();
              });
              return () => {
                setUserLogeado(null);
              };
           },[]);

        if (userLogeado) {
            return <UsuarioLogeadoScreen />
        } else {
            return <IniciarSesion />
        }
}
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { query, collection, where, onSnapshot, getFirestore} from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { size } from "lodash";
import { screenName } from "../../../utils/screenName";
import { styles } from "./BtnreviewForm.styles";


export function BtnReviewForm(props) {
const { negocio } = props;
const [usuarioLogeado, setUsuarioLogeado] = useState(null);
const [comentario, setComentario] = useState(null);
const navegacion = useNavigation();
const db = getFirestore();
const auth = getAuth();




useEffect(() => {
    onAuthStateChanged(auth, (user) => {
           setUsuarioLogeado(user ? true : false)
    });
    return () => {
      setUsuarioLogeado(null);
    };
},[]);

useEffect(() => {
    if (usuarioLogeado) {
        const q = query(
           collection(db, "reviews"),
           where("idNegocio", "==", negocio.id),
           where("idUser", "==", auth.currentUser.uid)
          );
            onSnapshot(q, (snapshot) => {
                 if (size(snapshot.docs) > 0) setComentario(true);
                 })
             };
          return () => {
            setComentario(null);
          }   
        }, [usuarioLogeado]);

  
   const irPaginaLogeo = () => {
    navegacion.navigate(screenName.cuenta.tab, { screen: screenName.cuenta.login })
   }

   const irAgregarOpinion = () => {
     navegacion.navigate(screenName.promociones.agregarReview, { negocio });
   }

   if (usuarioLogeado && comentario) {
    return(
      <View style={styles.content}>
        <Text style={styles.textSendReview}>Ya has enviado un comentario a este negocio.</Text>
      </View>
          );
       };


  return (
    <View style={styles.content}>
        {usuarioLogeado ? (
            <Button title="Escribe una opinion" 
                    icon={{ type: "material-community", name: "square-edit-outline", color: "#ff850c"}}
                    buttonStyle={styles.boton} 
                    titleStyle={styles.btnText}
                    onPress={irAgregarOpinion} />
        ) : (
          <View style={{alignItems: "center"}}>
             <Text style={styles.text}> Necesitas estar logeado para comentar</Text>
                  <Text style={styles.textClick} 
                        onPress={irPaginaLogeo} >Registrate AQUI
                  </Text>
          </View>
             )
       }
    </View>
  );
};

import * as Yup from "yup";

export function initialValues() {
   return {
        comentario: "",
        rating: 3,
    };
};

export function validationSchema() {
    return Yup.object({
        comentario: Yup.string()
                       .min(10, "Reseña muy corta")
                       .max(200, "Reseña con muchos caracteres")
                       .required("Campo obligatorio"),
        rating: Yup.number().required("Campo obligatorio"),
    });
};
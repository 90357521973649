import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        marginHorizontal: 15,
        marginTop: 10,
    },
    titulo: {
        fontSize: 28,
        alignSelf: "center",
        marginLeft: 0,
        fontWeight: "bold",
        marginTop: 20,
        color: "#00aeb7",
    }
});
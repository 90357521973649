import React, { useState } from "react";
import  { ListItem } from "react-native-elements";
import { map } from "lodash";
import { ListaNegociosUsuarios } from "./ListaNegociosUsuarios";



export function UsuariosIlimitadosLista(props) {
    const { filterUsuarios } = props; 
    const [expanded, setExpanded] = useState(false);


    return (
        map(filterUsuarios, (user, index) => {
            const data = user.data();
            return (
               <ListItem.Accordion key={index} content={
                <>
                  <ListItem.Content>
                    <ListItem.Title style={{color: "#0000FF"}} > {data.nombreEmpresa} </ListItem.Title>
                    <ListItem.Title> {data.id} </ListItem.Title>
                    <ListItem.Title style={{color: "#FF0000"}} > {data.correo} </ListItem.Title>
                  </ListItem.Content>
                </>
              }
              isExpanded={expanded}
              onPress={() => {
                setExpanded(!expanded);
              }} > 
                  <ListaNegociosUsuarios idUser={data.id} />
               </ListItem.Accordion>
            )
        })
    )
}
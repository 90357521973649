import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        alignItems: "center",
    },
    logoNegocio: {
        resizeMode: "cover",
        width: 450,
        height: 250,
        marginTop: 5,
    }, 
});
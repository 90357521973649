import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Icon } from "react-native-elements";
import { HomeStack } from "./HomeStack";
import { FavoritosStack } from "./FavoritosStack";
import { RankingStack } from "./RankingStack";
import { CuentaStack } from "./CuentaStack";
import { screenName } from "../utils/screenName";

const Tab = createBottomTabNavigator();

export function AppNavegacion() {
    return(
        <Tab.Navigator
        initialRouteName={screenName.promociones.tab}
        screenOptions={({ route }) => (
            {
               headerShown: false,  
               tabBarActiveTintColor: "orange",
               tabBarInactiveTintColor: "grey",
               tabBarIcon: ({ color, size }) => iconoBarra( route, color, size ),
             } 
          )}
            >
                <Tab.Screen name={screenName.home.tab} component={RankingStack} options={{title: "Ranking"}} />
                <Tab.Screen name={screenName.promociones.tab} component={HomeStack} options={{title: "Home"}} />
                <Tab.Screen name={screenName.favoritos.tab} component={FavoritosStack} options={{title: "Favoritos"}} />
                <Tab.Screen name={screenName.cuenta.tab} component={CuentaStack} options={{title: "Cuenta"}} />
        </Tab.Navigator>
    )
}

function iconoBarra(route, color, size ) {
    let iconName;
 
    if (route.name === screenName.home.tab ) {
     iconName = "star-outline";
    };
    if (route.name === screenName.promociones.tab) {
          iconName = "home-outline";
    };
    if (route.name === screenName.favoritos.tab) {
         iconName = "heart-outline";
    };
    if (route.name === screenName.cuenta.tab) {
     iconName = "account-outline";
    };
     return (
            <Icon type="material-community" name={iconName} color={color} size={size} />
     );
 };
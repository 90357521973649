import * as Yup from "yup";

export function initialValues() {
    return {
        nombreEmpresa: "",
        email: "",
        contrasena: "",
        repetirContrasena: "",
    };
};

export function validationSchema() {
    return Yup.object({
        nombreEmpresa: Yup.string()
                          .required("Nombre de empresa obligatorio"),
        email: Yup.string()
                  .email("El email no es correcto")
                  .required("El correo es requerido"),
        contrasena: Yup.string()
                       .required("La contraseña es obligatoria."),
        repetirContrasena: Yup.string()
                              .required("La contraseña es obligatoria")
                              .oneOf([Yup.ref("contrasena")], "Las contraseñas tienen que ser iguales"), 
    });
};
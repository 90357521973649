import React from 'react';
import { View } from 'react-native';
import { Image } from 'react-native-elements';
import { styles} from "./LogoNegocio.styles";

export function LogoNegocio(props) {
    const { negocio } = props;
   
  return (
     <View style={styles.content}>
        <Image source={{ uri : negocio.imgNegocio }} style={styles.logoNegocio} />
     </View>
  );
};

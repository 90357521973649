import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Icon, Text } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { screenName } from "../../../utils/screenName";



export function UsuarioNoLogeadoHome() {
  const navegacion = useNavigation();

  const irLogin = () => {
    navegacion.navigate(screenName.cuenta.tab, {
      screen: screenName.cuenta.login
    });
  };

  return (
    <View style={styles.content}>
      <Icon type='material-community' name='alert-outline' size={80} />
      <Text style={styles.text }>
        Necesitas estar logeado para ver esta seccion
      </Text>
      <Button title="Ir a login"
              containerStyle={styles.btnContainer}
              buttonStyle={styles.btn}
              onPress={irLogin} />
    </View>
  );
};




export const styles = StyleSheet.create({
    content: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    text: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        marginTop: 20,
    },
    btnContainer: {
        marginTop: 20,
        width: "80%",
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
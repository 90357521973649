import React, { useState } from 'react';
import { View } from 'react-native';
import { Button, Image, Input } from "react-native-elements";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./CambiarContrasenaForm.Datos";
import { styles } from "./CambiarContrasenaForm.styles";
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';

export function CambiarContrasenaForm(props) {
    const { close } = props;
 const [mostrarContrasena, setMostrarContrasena] = useState(false);

 const onShowPassword = () => setMostrarContrasena((prevState) => !prevState);

 const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
       try {
        const currentUser = getAuth().currentUser;
        const credentials = EmailAuthProvider.credential(
              currentUser.email,
              formValue.contrasenaActual
              );
        
             reauthenticateWithCredential(currentUser, credentials);
        await updatePassword(currentUser, formValue.contrasenaNueva);
        close();
       } catch (error) {
         alert("Error al cambiar contraseña de usuario, intente mas tarde.")
       }
    }
 });

  return (
    <View style={styles.content}>
      <View >
      <Image source={require("../../../../assets/img/finddin-logo.png")} 
             style={{resizeMode: "contain", width: 200, height: 50, marginBottom: 20}} 
             />
      </View>
        <Input placeholder='Contraseña actual'
               containerStyle={styles.input}
               secureTextEntry={mostrarContrasena ? false : true}
               rightIcon={{
                type: "material-community",
                name: mostrarContrasena ? "eye-off-outline" : "eye-outline",
                color: "#c2c2c2",
                onPress: onShowPassword, 
               }} 
               onChangeText={(text) => formik.setFieldValue("contrasenaActual", text) }
               errorMessage={formik.errors.contrasenaActual}
            />

        <Input placeholder='Contraseña nueva'
               containerStyle={styles.input}
               secureTextEntry={mostrarContrasena ? false : true}
               rightIcon={{
                type: "material-community",
                name: mostrarContrasena ? "eye-off-outline" : "eye-outline",
                color: "#c2c2c2",
                onPress: onShowPassword, 
               }} 
               onChangeText={(text) => formik.setFieldValue("contrasenaNueva", text) }
               errorMessage={formik.errors.contrasenaNueva}
            />
            <Input placeholder='Repite nueva contraseña'
               containerStyle={styles.input}
               secureTextEntry={mostrarContrasena ? false : true}
               rightIcon={{
                type: "material-community",
                name: mostrarContrasena ? "eye-off-outline" : "eye-outline",
                color: "#c2c2c2",
                onPress: onShowPassword, 
               }} 
               onChangeText={(text) => formik.setFieldValue("repetirContrasena", text) }
               errorMessage={formik.errors.repetirContrasena}
            />

        <Button title="Cambiar contraseña"
                containerStyle={styles.btnContainer} 
                buttonStyle={styles.btn} 
                onPress={formik.handleSubmit} 
                loading={formik.isSubmitting} />
    </View>
  );
};

import { NavigationContainer } from "@react-navigation/native";
import { AppNavegacion } from "./src/navegacion/AppNavegacion";
import { initFirebase } from "./src/utils/firebase";

export default function App() {
  return(
    <>
    <NavigationContainer>
        <AppNavegacion />
    </NavigationContainer>
    </>
  )
}
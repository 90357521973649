import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import { map } from "lodash";
import { styles } from "./Productos.styles";

export function Productos(props) {
    const { negocio } = props;
  return (
    <View style={styles.container}>
        <Text style={styles.titulo}>Productos y servicios</Text>
        <View style={{ marginHorizontal: 50,
                         marginBottom: 5,
                         flexDirection: "row", 
                         justifyContent: "space-evenly", flexWrap: "wrap", }} >
                {map(negocio.productos, (producto) => (
                    <Text key={producto} style={{ margin: 5, 
                                                  color: "#ff850c", 
                                                  fontSize: 25, 
                                                  fontWeight: "600",
                                                  
                                                  }} >{producto}</Text>
                 ))}
          </View>
    </View>
  );
};

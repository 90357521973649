import React from 'react';
import { ScrollView, Text } from 'react-native';



export function AvisodePrivacidad() {
   


  return (
    <ScrollView>
      <Text>
      Aviso de privacidad integral.
      </Text>
<Text>
Bruno Haros Garcia Travesi mejor conocido como Finddin, con domicilio en Calle Gaviota 14, Colonia.
Unidad Habitacional Independencia, Alcaldía Magdalena Contreras, 
CDMX, es el responsable del uso y protección de sus datos personales,
y al respecto le informamos lo siguiente:
</Text>
<Text>
¿Para qué fines utilizaremos sus datos personales?
</Text>
<Text>
Los datos personales que recabamos de usted,
los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
</Text>
<Text>
Prestación de cualquier servicio solicitado
</Text>
<Text>
¿Qué datos personales utilizaremos para estos fines?
</Text>
<Text>
Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
</Text>
<Text>
Datos de identificación y contacto
</Text>
<Text>
¿Con quién compartimos su información personal y para qué fines?
</Text>
<Text>
Le informamos que sus datos personales son compartidos dentro del país con las siguientes personas, empresas,
organizaciones o autoridades distintas a nosotros, para los siguientes fines:
</Text>
<Text>
Usuarios de Finddin, para conocer promociones, productos y servicios.
</Text>
<Text>
¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso o ejercer la revocación de consentimiento?
</Text>
<Text>
Usted tiene derecho a conocer qué datos personales tenemos de usted, 
para qué los utilizamos y las condiciones del uso que les damos (Acceso).
</Text>
<Text>
Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada,
sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que 
la misma no está siendo utilizada adecuadamente (Cancelación); 
así como oponerse al uso de sus datos personales para fines específicos (Oposición).
</Text>
<Text>
Estos derechos se conocen como derechos ARCO.
</Text>
<Text>
Para el ejercicio de cualquiera de los derechos ARCO, 
debe enviar una petición vía correo electrónico a soporteglobal2000@gmail.com y deberá contener:
</Text>
<Text>* Nombre completo del titular.</Text>
<Text>* Domicilio.</Text>
<Text>* Teléfono.</Text>
<Text>* Correo electrónico usado en la app.</Text>
<Text>* Copia de una identificación oficial adjunta.</Text>
<Text>* Asunto «Derechos ARCO»</Text>
<Text>
Descripción el objeto del escrito, los cuales pueden ser de manera enunciativa más no limitativa los siguientes:
</Text>
<Text>
Revocación del consentimiento para tratar sus datos personales; 
y/o Notificación del uso indebido del tratamiento de sus datos personales; 
y/o Ejercitar sus Derechos ARCO, con una descripción clara y precisa de los datos a Acceder,
Rectificar, Cancelar o bien, Oponerse.
</Text>
<Text> 
En caso de Rectificación de datos personales, deberá indicar la modificación exacta y anexar la documentación soporte; 
es importante en caso de revocación del consentimiento, 
que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata,
ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
</Text>
<Text>
Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir
prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
</Text>
<Text>
¿En cuántos días le daremos respuesta a su solicitud? 5 días
</Text>
<Text>
¿Por qué medio le comunicaremos la respuesta a su solicitud? Al mismo correo electrónico de donde se envío la petición.
</Text>
<Text>
¿Cómo puede conocer los cambios en este aviso de privacidad? 
El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales;
de nuestras propias necesidades por los productos o servicios que ofrecemos; 
de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
</Text>
<Text>
Nos comprometemos a mantener actualizado este aviso de privacidad sobre los cambios que pueda sufrir y 
siempre podrá consultar las actualizaciones que existan en la app.
</Text>
    </ScrollView>
  );
};

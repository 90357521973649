import React, { useEffect, useState } from "react";
import  { ListItem } from "react-native-elements";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { map } from "lodash";
import { db } from "../../../utils";
import { BotonEliminar } from "../BotonEliminar";



export function ListaNegociosUsuarios(props) {
    const { idUser } = props;
    const [negociosUser, setNegociosUser] = useState(null);


useEffect(() => {
     const q = query(
        collection(db, "negocios"),
        where("idUser", "==", idUser)
                   );
         onSnapshot(q, (snapshot) => {
            setNegociosUser(snapshot.docs);
                 });
          return () => {
            setNegociosUser(null);
                 };
                 },[idUser]);    

    return (
        map(negociosUser, (negocio, index) => {
            const data = negocio.data();
             return(
              <ListItem key={index} bottomDivider >
              <ListItem.Content>
                <ListItem.Title>{data.nombre}</ListItem.Title>
              </ListItem.Content>
              <BotonEliminar 
                    key={index} 
                    negocio={data}
                   />  
          </ListItem>
               );
            })
    );
}
import React from 'react';
import { View, Share } from 'react-native';
import { Text, Rating, Icon } from 'react-native-elements';
import { styles } from './Header.styles';

export function Header(props) {
    const { negocio } = props;

    
  return (
    <View style={styles.content}>
      <Text style={styles.name}>{negocio.nombre}</Text>
      <Text style={styles.descripcion}>{negocio.descripcion}</Text>
        <View style={styles.titleView}> 
           <Text style={{fontSize: 18, color: "#ff850c"}}>Calificacion Usuarios:</Text>
           <Rating imageSize={20} 
                   readonly 
                    startingValue={negocio.ratingMedia | 0} 
                    />
        </View>
    </View>
  );
};

import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Icon, Image, Text } from 'react-native-elements';
import { useNavigation } from "@react-navigation/native";
import { doc, deleteDoc } from "firebase/firestore";
import { screenName, db } from "../../../utils";
import { styles } from "./NegociosFavoritos.styles";


export function NegociosFavoritos(props) {
   const { negocio } = props;
   const navegacion = useNavigation();



   const irNegocio = () => {
    navegacion.navigate(screenName.promociones.tab, {
      screen: screenName.promociones.negocio,
      params: {
         id: negocio.id
      }
    });
   };

   const removerNegocio = async () => {
         try {
            await deleteDoc(doc(db, "favoritos", negocio.idFavorito))
         } catch (error) {
            console.log(error);
         }
   };

    return ( 
   <TouchableOpacity onPress={irNegocio}>
      <View style={styles.content}>
         <Image source={{ uri: negocio.imgNegocio }} style={styles.imagen} />
         <View style={styles.infoContent}>
            <Text style={styles.nombre}>{negocio.nombre}</Text>
            <Icon type='material-community' 
                  name='heart' 
                  color="#f00" 
                  size={35} 
                  containerStyle={styles.icon}
                  onPress={removerNegocio} />
         </View>
     </View>
    </TouchableOpacity>
     );
  };

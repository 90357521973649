import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        margin: 10,
        marginHorizontal: 5,
    },
    titleView: {
        marginTop: 10,
        alignItems: "center",
        
    },
    name: {
        fontSize: 35,
        fontWeight: "bold",
        color: "#ff850c",
    },
    descripcion: {
        fontSize: 18,
        alignSelf: "center",
        marginTop: 10,
        color: "#828282",
    }
});
import React, { useState } from 'react';
import { View } from 'react-native';
import { Text, SearchBar, Button } from "react-native-elements";
import { size } from "lodash";
import { NegociosConUbicacion } from "./ConUbicacion/NegociosConUbicacion";
import { NegociosSinUbicacion } from "./SinUbicacion/NegociosSinUbicacion";
import { remove } from "remove-accents";
import { styles } from "./ListaNegocios.styles";

export function ListaNegocios(props) {
    const { promociones, promocionesTodas, location, loading, reload, setLoading, setLoading2, setPromocionesTodas } = props;
    const [texto, setTexto] = useState("");
    const [filterData, setFilterData] = useState(promociones);
    const [filterDataTodos, setFilterDataTodos] = useState(promocionesTodas); 


    const searchFilterConUbicacion = (text) => {
          if (text) {
            const newData = promociones.filter((item) => {
            const negocio = item.data();
            const nombre = remove(negocio.nombre);
            const producto = negocio.productos 
            const giro = negocio.giroNegocio;
            const promocion = remove(negocio.promociones[0].descripcionPromocion);
            const search = nombre + producto + giro + promocion
            const itemData = search ? 
                             search.toUpperCase() : ''.toUpperCase();
            const textData = text.toUpperCase().trim();
            return itemData.indexOf(textData) > -1;                               
                });
                setFilterData(newData);
                setFilterDataTodos(newData);
                setTexto(text);
              
               if (size(newData) === 0) {   
               const newData = promocionesTodas.filter((item) => {
                const negocio = item.data();  
                const nombre = remove(negocio.nombre);
                const producto = negocio.productos 
                const giro = negocio.giroNegocio;
                const promocion = remove(negocio.promociones[0].descripcionPromocion);
                const search = nombre + producto + giro + promocion
                const itemData = search ? 
                                 search.toUpperCase() : ''.toUpperCase();
                const textData = text.toUpperCase().trim();
                return itemData.indexOf(textData) > -1;                               
                  });
                  setFilterData(newData);
                  setFilterDataTodos(newData);
                  setTexto(text);
              }
       } else {
            setFilterData(promociones);
            setFilterDataTodos(promocionesTodas);
            setTexto(text);
             }
         };


    const searchFilterSinUbicacion = (text) => {
      if (text) {
        const newData = promocionesTodas.filter((item) => {
        const negocio = item.data();  
        const nombre = remove(negocio.nombre);
        const producto = negocio.productos 
        const giro = negocio.giroNegocio;
        const promocion = remove(negocio.promociones[0].descripcionPromocion);
        const search = nombre + producto + giro + promocion
        const itemData = search ? 
                         search.toUpperCase() : ''.toUpperCase();
        const textData = text.toUpperCase().trim();
        return itemData.indexOf(textData) > -1;                               
            });
            setFilterDataTodos(newData);
            setTexto(text);
   }  else {
     setFilterDataTodos(promocionesTodas);
     setTexto(text);
      }
};


  if (location && promociones && promocionesTodas) {
    return(
      <>
      <SearchBar 
       placeholder='Que estas buscando?'
       platform="default"
       value={texto}
       placeholderTextColor={'#ff850c'}
       containerStyle={{backgroundColor: "#ff850c" }}
       inputContainerStyle={{backgroundColor: "white"}}
       onChangeText={(text) => searchFilterConUbicacion(remove(text))}
      />
      <View style={styles.mapContent}>
      <Text style={styles.titulo}>Promociones Cercanas</Text>
      </View>   
      <NegociosConUbicacion promociones={promociones}
                            filterDataTodos={filterDataTodos}
                            filterData={filterData}
                             />
     </>
      );
    };

    if (loading && promocionesTodas) {
      return(
        <>
        <SearchBar 
         placeholder='Que estas buscando?'
         platform="default"
         value={texto}
         placeholderTextColor={'#ff850c'}
         containerStyle={{backgroundColor: "#ff850c" }}
         inputContainerStyle={{backgroundColor: "white"}}
         onChangeText={(text) => searchFilterSinUbicacion(text)}
        />
        <View style={styles.mapContent}>
        <Text style={{fontSize: 18, 
                      marginTop: 12,
                      marginBottom: 5,
                      color: "#00aeb7" }}>El mapa aparece al activar ubicacion</Text>
        <Button title="Activar ubicacion"
              containerStyle={styles.btnContainer}
              buttonStyle={styles.btn}
              onPress={() => {
                setPromocionesTodas(null);
                setLoading(false);
                setLoading2(false);
                reload();
                }}
               />
        </View>   
        <NegociosSinUbicacion filterDataTodos={filterDataTodos}
                               />
       </>
        );
      };
}; 
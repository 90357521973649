import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBL9-uSLPotjQRT71Dj1P06wixsGAfB49g",
  authDomain: "finddin9.firebaseapp.com",
  projectId: "finddin9",
  storageBucket: "finddin9.appspot.com",
  messagingSenderId: "1059420075685",
  appId: "1:1059420075685:web:302ef4e107c720f1fa7f4c",
  measurementId: "G-EMHQ0W6KWK"
};

export const initFirebase = initializeApp(firebaseConfig);
export const db = getFirestore(initFirebase);
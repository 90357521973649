import React, { useState } from 'react';
import { View } from 'react-native'; 
import { Input, Text, Button, Image, Overlay } from 'react-native-elements';
import { v4 as uuid } from "uuid";
import { getAuth } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../utils";
import { useNavigation } from "@react-navigation/native";
import { useFormik } from "formik";
import { initialValues, validationSchema} from "./ReportesFormData";



export function ReportesForm(props) {
    const { idUser, nombreNegocio, visible, setVisible, toggleOverlay } = props;
    const navegacion = useNavigation();
   

       const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
          try {
            const auth = getAuth();
            const idDoc = uuid();
            const newData = formValue;
            newData.id = idDoc;
            newData.nombre = nombreNegocio;
            newData.idNegocio = idUser;
            newData.idUser = auth.currentUser.uid;
            newData.createdAt = new Date();
              
            await setDoc(doc(db, "reportes", idDoc), newData);
            setVisible(false);
            alert("Gracias por ayudarnos a mejorar finddin, tu reporte fue enviado correctamente.")
           } catch (error) {
            console.log(error)
            alert("Error al enviar reporte, intente mas tarde.")
           }
        }
    });



   
        
 

  return (
    <Overlay isVisible={visible} 
              onBackdropPress={toggleOverlay} 
              overlayStyle={{width: "95%", height: "auto",}} >
    <View>
    <View>
         <Image source={require("../../../../assets/img/finddin-logo.png")} 
             style={{ resizeMode: "contain",
                      width: "100%",
                      height: 100,
                      marginTop: 0, 
                      marginBottom: 5,}} /> 
         </View>
    <Input placeholder='Describe el motivo del reporte'
           multiline 
           style={{ height: 150 }}
           onChangeText={(text) => {
            formik.setFieldValue("descripcionReporte", text);
                }}
            errorMessage={formik.errors.descripcionReporte}
           />
           <Button title="Enviar reporte"
              buttonStyle={{ marginTop: 25, backgroundColor: "#FF0000"}}
              onPress={formik.handleSubmit}
              loading={formik.isSubmitting}
               />
             
    </View> 
  </Overlay>
   
  );
};


import React from 'react';
import { View } from 'react-native';
import { AirbnbRating, Image, Input, Button } from 'react-native-elements';
import { useFormik } from "formik";
import { getAuth } from "firebase/auth";
import {collection, doc, onSnapshot, updateDoc, query, setDoc, where } from "firebase/firestore";
import { db } from "../../../../utils/firebase";
import { v4 as uuid} from "uuid";
import { map, mean } from "lodash";
import { useNavigation } from "@react-navigation/native";
import { initialValues, validationSchema } from "./AgregarReview.Data";
import { styles } from "./AgregarReview.styles";



export function AgregarReview(props) {
    const { route } = props;
    const navegacion = useNavigation();



    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: validationSchema(),
        validateOnChange: true,
        onSubmit: async (formValue) => {
          try {
            const auth = getAuth();
            const idDoc = uuid();
            const newData = formValue;
            newData.id = idDoc;
            newData.idNegocio = route.params.negocio.id;
            newData.idUser = auth.currentUser.uid;
            newData.avatar = auth.currentUser.photoURL;
            newData.createdAt = new Date();
              
            await setDoc(doc(db, "reviews", idDoc), newData);
            await actualizarNegocio();
            navegacion.goBack();
            
           } catch (error) {
            alert("Error al enviar comentario");
           }
        }
    });
   
     const actualizarNegocio = async () => {
             const q = query(
                  collection(db, "reviews"),
                  where("idNegocio", "==", route.params.negocio.id)
                  );
   onSnapshot(q, async (snapshot) => {
          const reviews = snapshot.docs;
          const arrayStars = map(reviews, (review) => review.data().rating);
          const media = mean(arrayStars);
          const negocioRef = doc(db, "negocios", route.params.negocio.id);
          await updateDoc(negocioRef, {
                    ratingMedia: media,
                    });
                 });
           };



  return (
    <View style={styles.content}>
        <View style={styles.ratingContent}>
            <Image source={require("../../../../../assets/img/finddin-logo.png")} style={styles.logo} />
                <AirbnbRating 
                    count={5}
                    reviews={["Pesimo","Deficiente","Normal","Muy Bueno","Excelente"]}
                    defaultRating={3}
                    size={35}
                    onFinishRating={(rating) => formik.setFieldValue("rating", rating) } />
            </View>
            <View>
                <Input placeholder='Comentario' 
                       multiline
                       inputContainerStyle={styles.comentario}
                       onChangeText={(text) => formik.setFieldValue("comentario", text)}
                       errorMessage={formik.errors.comentario}
                       />
            </View>
     <Button title="Enviar opinion"
             containerStyle={styles.btnContainer} 
             buttonStyle={styles.btn}
             onPress={formik.handleSubmit} 
             loading={formik.isSubmitting}
             />
    </View>
  );
};

import React from 'react';
import { ScrollView, View } from 'react-native';
import { Image, Text } from "react-native-elements";
import { RegistroForm } from "../../../componentes/Auth/RegistroForm/RegistroForm";
import { styles } from "./RegistroUsuario.styles"


export function RegistroUsuario() {
  return (
      <ScrollView>
        <View style={{marginHorizontal: 40}}>
        <Image source={require("../../../../assets/img/finddin-logo.png")} 
                  style={{resizeMode: "contain", width: "100%", height: 100, marginTop: 20}} />
        </View>
        <View style={styles.content}>
           <RegistroForm />
        </View>
        <View style={{ alignItems: "center", marginTop: 25, marginBottom: 10, marginHorizontal: 40}}>
         <Text style={{fontSize: 25, 
                       fontWeight: "bold", 
                       color: "#00aeb7",
                       textAlign: "center",
                       marginTop: 10,
                       marginBottom: 25,
                       }}>
                 Ingresa correo y contraseña para unirte a la mayor comunidad de promociones en mexico.
                 </Text>
           </View>
    </ScrollView>
  );
};

import { StyleSheet } from "react-native";



export const styles = StyleSheet.create({
    content: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
    }, 
    input: {
        width: "100%",
        marginTop: 20,
    },
    icon: {
        color: "#c1c1c1",
    },
    btnContainer: {
        marginTop: 20,
        width: "95%",
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
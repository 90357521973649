import React from 'react';
import { StyleSheet, View } from "react-native";
import { Image } from "react-native-elements";
import { map } from 'lodash';


export function Carousel(props) {
    const { arrayImages } = props;


      return( 
        map(arrayImages, (item, index) => {
          return (
            <View key={index}
                  style={styles.content}
                               >            
                <Image style={styles.imagen}
                       source={{uri: item}} />
            </View>
          );
        })
     )
  };




const styles = StyleSheet.create({
    content: {
     alignItems: "center",
     margin: 2
         },
    imagen: {
        resizeMode: "center",
        height: 260,
        width: 260,
    },
});
import { StyleSheet } from "react-native"; 




export const styles = StyleSheet.create({
    content: {
        marginTop: 20,
        marginHorizontal: 40,
    },
    title:{
        fontWeight: "bold",
        fontSize: 19,
        marginTop: 20,
        marginBottom: 10,
        textAlign: "center",
    },
    description:{
        textAlign: "center",
        fontSize: 17,
        color: "#00aeb7",
        marginBottom: 20,
    },
    textRegistro: {
        fontSize: 18,
        marginTop: 15,
        marginHorizontal: 20,
    },
    btnRegistro: {
        color: "white",
        backgroundColor: "#ff850c",
        fontWeight: "bold",
        borderRadius: 10,
        padding: 12,
        marginTop: 5,
        marginBottom: 10,
        fontSize: 20,
    }
});
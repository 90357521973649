import React from 'react';
import { View } from 'react-native';
import { Button, Image, Input}  from "react-native-elements";
import { useFormik } from "formik";
import { getAuth, updateProfile} from "firebase/auth";
import { initialValues, validationSchema} from "./CambiarNombre.Data";
import { styles } from "./CambiarNombreForm.styles";


export function CambiarNombreForm(props) {
  const { close, onReload } = props;

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: "false",
    onSubmit: async (formValue) => {
       try {
        const { nombreUsuario } = formValue;
        const currentUser = getAuth().currentUser;
        await updateProfile(currentUser, { displayName: nombreUsuario });
        onReload();
        close();
       } catch (error) {
         alert("Error al cambiar nombre de usuario, intente mas tarde.")
       }
    }
  });

  return (
    <View style={styles.content}>
      <View>
        <Image source={require("../../../../assets/img/finddin-logo.png")} 
               style={{resizeMode: "contain", width: 200, height: 50, marginBottom: 20}} 
                />
        </View>
       <Input placeholder='Cambiar nombre' 
               rightIcon={{
                   type: "material-community",
                   name: "account-circle-outline",
                   color: "#c2c2c2",
               }}
               onChangeText={(text) => formik.setFieldValue("nombreUsuario", text) }
               errorMessage={formik.errors.nombreUsuario}
                />
         <Button title="Cambiar nombre y apellidos" 
                 containerStyle={styles.btnContainer} 
                 buttonStyle={styles.btn}
                 onPress={formik.handleSubmit}
                 loading={formik.isSubmitting} />    
    </View>
  );
};

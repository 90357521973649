import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { FavoritosScreen } from "../screens/favoritos/FavoritosScreen";
import { screenName } from "../utils/screenName";


const Stack = createNativeStackNavigator();

export function FavoritosStack() {

    return(
        <Stack.Navigator>
            <Stack.Screen 
            name={screenName.favoritos.favoritos}
            component={FavoritosScreen}
            options={{title: "Favoritos"}}
         />
        </Stack.Navigator>
    );
}
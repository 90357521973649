import { StyleSheet } from "react-native";



export const styles = StyleSheet.create({
    content: {
        alignItems: "center",
        width: "95%",
        paddingVertical: 10,
    },
    input: {
        marginBottom: 20,
    },
    btnContainer: {
        width: "90%",
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
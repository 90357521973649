import * as Yup from "yup";

export function initialValues() {
    return {
        descripcionReporte: "",
    };
};

export function validationSchema() {
    return Yup.object({
        descripcionReporte: Yup.string()
                  .min(15, "Explica mejor el motivo")
                  .max(250, "Mensaje muy largo")
                  .required("Campo requerido"), 
    });
};

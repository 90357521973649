import React, { useState } from 'react'
import { View } from 'react-native';
import { Avatar, Text } from "react-native-elements";
import { getAuth, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import * as ImagePicker from "expo-image-picker";
import { styles } from "./InfoUser.styles";


export function InfoUser(props) {
  const { setLoading, setLoadingText} = props;
  const { uid, photoURL, displayName, email} = getAuth().currentUser; 
  const [ avatar, setAvatar] = useState(photoURL);

  console.log(photoURL);

  const cambiarAvatar = async ()=>{
        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [4, 3],
        });
      if (!result.canceled) subirImagen(result.assets[0].uri);
  };

  const subirImagen = async (uri) => {
        setLoadingText("Actualizando");
        setLoading(true);

        const respuesta = await fetch(uri);
        const blob = await respuesta.blob();
   
        const storage = getStorage();
        const storageRef = ref(storage, `avatar/${uid}`);

        uploadBytes(storageRef, blob).then((snapshot) => {
               actualizarFotoUrl(snapshot.metadata.fullPath);
        })
      };

      const actualizarFotoUrl = async (imagePath) => {
            const storage = getStorage();
            const imageRef = ref(storage, imagePath);

            const imageUrl = await getDownloadURL(imageRef);

            const auth = getAuth();
            updateProfile(auth.currentUser, { photoURL : imageUrl});
            setAvatar(imageUrl);
            setLoading(false);
      };

  return (
  <View style={styles.content}>
      <Avatar
       size="large"
       rounded
       containerStyle={styles.avatar}
       icon={{ type: "material", name: "person" }}
       source={{ uri: avatar }}
      >
        <Avatar.Accessory size={24} onPress={cambiarAvatar} />
      </Avatar>
      <View>
      <Text style={styles.displayName}>{displayName || "Anonimo"}</Text>
      <Text>{email}</Text>
      </View>
  </View>
  );
};

import React from 'react';
import { View } from "react-native";
import { Icon, Text } from "react-native-elements";
import { styles } from "./SinFavoritos.styles";

export function SinFavoritos() {
  return (
    <View style={styles.content}>
        <Icon type='material-community'
              name='alert-outline'
              size={80} 
              iconStyle={{color: "#ff850c"}} />
        <Text style={styles.text}>No tienes negocios agregados en tu lista de favoritos</Text>
    </View>
  );
};

import * as Yup from "yup";

export function initialValues() {
    return {
        email: "",
        contrasena: "",
    };
};

export function validationSchema() {
    return Yup.object({
        email: Yup.string()
                  .email("El email no es correcto")
                  .required("El correo es requerido"),
        contrasena: Yup.string()
                       .required("La contraseña es obligatoria."),                  
    });
};
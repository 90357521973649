import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        alignItems: "center",
        marginHorizontal: 28,
    },
    review: {
        paddingVertical: 20,
    
    },
    info: {
        flex: 1,
        flexDirection: "column",
        marginTop: 5,
    },
    comentario: {
        paddingRight: 70,
        
       },
       contentRatingDate: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 10,
       },
       starContainer: {
        flex: 1,
        width: "100%",
        justifyContent: "flex-start",
       },
       date: {
        fontSize: 12,
        color: "#828282"
       }
});
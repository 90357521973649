import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    container: {
        alignItems: "center",
    },
    titulo: {
        fontSize: 28,
        marginLeft: 0,
        fontWeight: "bold",
        marginTop: 20,
        color: "#00aeb7",
    }
});
import * as Yup from "yup";

export function initialValues() {
    return {
        nombreUsuario: "",
    };
};


export function validationSchema() {
    return Yup.object({
        nombreUsuario: Yup.string()
                          .max(20, "El nombre de usuario es demasiado largo")
                          .required("Nombre de usuario requeridos"),
    });
};
import React from 'react';
import { View } from 'react-native';
import { ListItem, Icon, Text } from "react-native-elements";
import { map } from "lodash";
import { styles } from "./Info.styles";

export function Info(props) {
    const { negocio } = props;

    const listInfo = [
        {
            text: negocio.direccion,
            iconType: "material-community",
            iconName: "map-marker",
        },
        {
            text: negocio.horarios,
            iconType: "material-community",
            iconName: "timer-outline",
        },
        {
            text: negocio.telefono,
            iconType: "material-community",
            iconName: "phone",
        },
        {
            text: negocio.formasPago,
            iconType: "material-community",
            iconName: "cash-register",
        },
        {
            text: negocio.servicioDomicilio,
            iconType: "material-community",
            iconName: "motorbike",
        },
    ];

  return (
    <>
    <Text style={styles.titulo}>Ubicación e Información</Text>
    <View style={styles.content}>    
        {map(listInfo, (item, index) => (
            <ListItem key={index} bottomDivider >
                <Icon type={item.iconType} name={item.iconName} color="#ff850c"/>
                <ListItem.Content>
                    <ListItem.Title>{item.text}</ListItem.Title>
                </ListItem.Content>
            </ListItem>
        ) )}
    </View>
    </>
  );
};


import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    header:{
       alignItems: "center",
    },
    logo: {
        resizeMode: "contain",
        width: "100%",
        height: 100,
        marginTop: 2, 
        marginBottom: 5,
    },
    mapContent: {
        alignItems: "center",
    },
    titulo: {
        marginTop: 15,
        marginBottom: 15,
        color: "#ff850c",
        fontSize: 30,
        fontWeight: "bold",
    },
    btnContainer: {
        marginTop: 5,
        marginBottom: 10,
        width: "80%",
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
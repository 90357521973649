import React from 'react'
import { Alert, View } from "react-native";
import { Button, Input, Icon, Image} from "react-native-elements";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useFormik } from "formik";
import { initialValues, validationSchema} from "./RestablecerPassword.Data";
import { screenName } from "../../../utils";

export function RestablecerPassword({ navigation }) {

  const formik = useFormik({
      initialValues: initialValues(),
      validationSchema: validationSchema(),
      validateOnChange: true,
  
      onSubmit: async (formValue) => {
                const auth = getAuth();
                await sendPasswordResetEmail(auth, formValue.email)
                .then(() => {
                  Alert.alert('Confirmacion', 'En unos minutos llegara un correo para restablecer tu contraseña.');
                  navigation.navigate(screenName.cuenta.login);
                  console.log(auth);
                  console.log(formValue.email);
                })
                .catch((error) => {
                   console.log(error.message);
                });
      }
    });


  return (    
    <>
     <View style={{marginHorizontal: 20, marginTop: 20}} >
     <Image source={require('../../../../assets/img/finddin-logo.png')}
            style={{resizeMode: "contain", width: "100%", height: 100}}
         />
     </View>
      <View style={{flex: 1, alignItems: "center", justifyContent: "center", marginHorizontal: 20}}>
       <Input 
        placeholder='Correo Electronico'
        containerStyle={{width: "90%"}}
        onChangeText={(email) => formik.setFieldValue("email", email)}
        keyboardType="email-address"
        errorMessage={formik.errors.email}
        rightIcon={
          <Icon 
           type='material-community'
           name='at'
          />
        }
        />
        <Button title='Enviar correo'
                containerStyle={{ alignSelf: "center", width: "85%", marginTop: 30}}
                buttonStyle={{backgroundColor: "orange"}}
                onPress={formik.handleSubmit}
                loading={formik.isSubmitting}
              />
    </View>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { ScrollView, View } from "react-native";
import { Button, Image, Text } from "react-native-elements";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { query, collection, where, onSnapshot, setDoc, doc, deleteDoc } from "firebase/firestore";
import { InfoUser } from '../../../componentes/Cuenta/InfoUser/InfoUser';
import { OpcionesCuenta } from '../../../componentes/Cuenta/OpcionesCuenta';
import { useNavigation } from "@react-navigation/native";
import { db, screenName } from "../../../utils";
import { map } from "lodash";
import { styles } from './UsuarioLogeado.styles';



export function UsuarioLogeadoScreen() {
  const [loading, setLoading] = useState(false);
  const [logeado, setLogeado] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [_, setOnReload] = useState(false);
  const [negocios, setNegocios] = useState(null);
  const navegacion = useNavigation();
  const auth = getAuth();
  const onReload = () => setOnReload((prevState) => !prevState);

  const logout = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  const userId = getAuth().currentUser.uid;
  const email = getAuth().currentUser.email;
  const nombre = getAuth().currentUser.displayName;


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
    setLogeado(user ? true : false);
            })
        return () => {
          setLogeado(null);
        }
        },[]);

       useEffect(() => {
          const q = query(
             collection(db, "negocios"),
             where("idUser", "==", userId),
          );
         onSnapshot(q, (snapshot) => {
             const docs = snapshot.docs;
               setNegocios(docs);
             });
             return () => {
              setNegocios(null);
          }
      },[logeado]);


  return(
    <ScrollView>
       <InfoUser setLoading={setLoading} setLoadingText={setLoadingText} />
       <OpcionesCuenta onReload={onReload} 
                       negocios={negocios} 
                       email={email}
                       nombre={nombre}
                       />
       <Button
           title="Cerrar sesion" 
           buttonStyle={styles.btnStyles} 
           titleStyle={styles.btnTextStyle}
           onPress={logout} />
       <View style={{ alignItems: "center", marginTop: 15}}>
           <Text style={{ color: "#0000ff", 
                    textDecorationLine: "underline",
                    fontSize: 18,
                    marginBottom: 5}}
            onPress={() => {
              navegacion.navigate(screenName.cuenta.avisodePrivacidad)
                            }}
                    >Aviso de Privacidad
          </Text>   
          <Text style={{ color: "#0000ff",
                         alignItems: "center", 
                         textDecorationLine: "underline",
                         fontSize: 18}}
            onPress={ () => {
              navegacion.navigate(screenName.cuenta.terminosyCondiciones)
            }}
                 > Terminos y Condiciones
         </Text>
         </View>
      {userId === "N6HQu519Dqeld19vcsMonvUDdPS2" ? (
           <View style={{alignItems: "center", marginTop: 15}} >
             <Text style={{fontSize: 21, fontWeight: "bold"}} >Administracion de Usuarios</Text>
               <Button title="Negocios Todos" 
                     buttonStyle={{margin: 5, backgroundColor: "#ff850c"}}
                     onPress={() => {
                       navegacion.navigate(screenName.cuenta.usuariosTodos);
                              }}  />
               <Button title="Usuarios Ilimitados" 
                     buttonStyle={{margin: 5, backgroundColor: "#ff850c"}}
                     onPress={() => {
                       navegacion.navigate(screenName.cuenta.usuariosIlimitados);
                              }} />
               <Button title="Negocios Reportados"
                     buttonStyle={{margin: 5, backgroundColor: "#FF0303"}}
                     onPress={() => {
                       navegacion.navigate(screenName.cuenta.negociosReportados);
                              }} />
             </View>
         ) : null }
        <View style={{ marginTop: 50,}}>
         <Image source={require('../../../../assets/img/contacto.jpeg')}
            style={{width: "100%", height: 200 , resizeMode: "cover"}} />
        </View>
    </ScrollView>
  );
}
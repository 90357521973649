import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { CuentaScreen } from "../screens/cuenta/CuentaScreen";
import { RegistroUsuario } from "../screens/cuenta/RegistrarUsuario/RegistroUsuario";
import { IniciarSesion } from "../screens/cuenta/IniciarSesion/IniciarSesion";
import { RestablecerPassword } from "../screens/cuenta/RestablecerPassword/RestablecerPassword";
import { TerminosyCondiciones } from "../screens/cuenta/TerminosyCondiciones";
import { AvisodePrivacidad } from "../screens/cuenta/AvisodePrivacidad";
import { UsuariosIlimitadosScreen } from "../screens/cuenta/UsuariosIlimitados/UsuariosIlimitadosScreen";
import { UsuariosTodosScreen } from "../screens/cuenta/UsuariosTodos/UsuariosTodosScreen";
import { NegociosReportados } from "../screens/cuenta/NegociosReportados/NegociosReportados";
import { screenName } from "../utils/screenName";

const Stack = createNativeStackNavigator();

export function CuentaStack() {
    return(
        <Stack.Navigator>
            <Stack.Screen 
            name={screenName.cuenta.cuenta}
            component={CuentaScreen}
            options={{title: "Cuenta"}}
            />
           <Stack.Screen 
            name={screenName.cuenta.login}
            component={IniciarSesion}
            options={{title: "Iniciar Sesion"}}
            />
           <Stack.Screen 
            name={screenName.cuenta.registro}
            component={RegistroUsuario}
            options={{title: "Crea tu Cuenta"}}
         />
          <Stack.Screen 
            name={screenName.cuenta.restablecerContrasena}
            component={ RestablecerPassword }
            options={{ title: "Recuperar cuenta" }}
             />
           <Stack.Screen 
            name={screenName.cuenta.terminosyCondiciones}
            component={ TerminosyCondiciones }
            options={{ title: "Terminos y condiciones" }}
             />
           <Stack.Screen 
            name={screenName.cuenta.avisodePrivacidad}
            component={ AvisodePrivacidad }
            options={{ title: "Aviso de privacidad" }}
             />
             <Stack.Screen 
             name={screenName.cuenta.usuariosIlimitados}
             component={ UsuariosIlimitadosScreen }
             options={{ title: "Usuarios Ilimitados"}}
            />
           <Stack.Screen 
             name={screenName.cuenta.usuariosTodos}
             component={ UsuariosTodosScreen }
             options={{ title: "Usuarios Todos"}}
            />  
           <Stack.Screen 
             name={screenName.cuenta.negociosReportados}
             component={ NegociosReportados }
             options={{ title: "Negocios reportados"}}
            /> 
        </Stack.Navigator>
    );
}
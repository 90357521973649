import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
       alignItems: "center",
       width: "95%",
       paddingVertical: 10,
    },
    btnContainer: {
        width: "90%",
        marginTop: 10,
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
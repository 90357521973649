import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View, StyleSheet } from "react-native";
import { Button, Text } from "react-native-elements";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import * as Location from 'expo-location';
import geohash from "ngeohash";
import { db } from "../../utils/firebase";
import { ListaNegocios } from "../../componentes/Negocios/ListaNegocios/ListaNegocios";



export function HomeScreen() {
   const [promociones, setPromociones] = useState(null);
   const [promocionesTodas, setPromocionesTodas] = useState(null);
   const [location, setLocation] = useState(null);
   const [loading, setLoading] = useState(false);
   const [loading2, setLoading2] = useState(false);
   const [loadingButton, setLoadingButton] = useState(false);
   const [onReload, setOnReload] = useState(false);
   const [botonCargar, setBotonCargar] = useState(false);

   console.log(location);

   const reload = () => setOnReload((prevState) => !prevState);
        
   useEffect(() => {
        ( async () => {
          const { status } = await Location.requestForegroundPermissionsAsync();
             if (status !== "granted") {
             alert('Has bloqueado la ubicacion, no apareceran los negocios cercanos a tu ubicacion, activala manualmente desde las opciones de tu celular o instala nuevamente la aplicacion.');
             const q = query(
               collection(db, "promociones"),
                   );
               onSnapshot(q, (snapshot) => {
               setPromocionesTodas(snapshot.docs);
               setLoading(true);
                });
             return;                                                   
              }
           const location = await Location.getCurrentPositionAsync({});
                   setLocation({
                      latitude: location.coords.latitude, 
                      longitude: location.coords.longitude,
                      latitudeDelta: 0.001,
                      longitudeDelta: 0.001,
                                 });
          const hash = geohash.encode(location.coords.latitude, location.coords.longitude, 6);
                   const q =  query(
                             collection(db, "promociones"),
                             where("geohash","==", hash)
                                  );
                             onSnapshot(q, (snapshot) => {
                              setPromociones(snapshot.docs);              
                                  });
                           
                                       
                    const q2 = query(
                              collection(db, "promociones"),
                                  );
                              onSnapshot(q2, (snapshot) => {
                              setPromocionesTodas(snapshot.docs);
                              setLoading2(true);
                             });
                           })();
               return () => {
                           setLocation(null);
                           setPromociones(null);
                           setPromocionesTodas(null);
                              };
                             },[onReload]);     
                                                  

   setTimeout(() => {
      setBotonCargar(true);
             }, 6000);


  if (!loading2 && !loading) {
   return (
      <View style={styles.content}>
      <ActivityIndicator  size="large" color="#ff850c" />
      <Text style={styles.text}>Cargando</Text>    
      {botonCargar &&  <Button title='Recargar pagina'
                  buttonStyle={styles.btnRegistro}
                  onPress={() => {
                    setLoadingButton(true);
                    reload();
                  }}
                  loading={loadingButton} /> 
            }
      </View>
   )
  }

  return (
    <ScrollView>
          <ListaNegocios promociones={promociones}
                         promocionesTodas={promocionesTodas}
                         setPromocionesTodas={setPromocionesTodas}
                         location={location} 
                         setLocation={setLocation}
                         loading={loading}
                         reload={reload}
                         setLoading={setLoading}
                         setLoading2={setLoading2}
                      /> 
    </ScrollView>
  );
};



export const styles = StyleSheet.create({
   content: {
       flex: 1,
       alignItems: "center",
       justifyContent: "center",
   },
   text: {
       color: "#00aeb7",
       textTransform: "uppercase",
       marginTop: 10,
       marginBottom:5,
   },
   btnRegistro: {
      color: "white",
      backgroundColor: "#ff850c",
      fontWeight: "bold",
      borderRadius: 10,
      padding: 10,
      marginTop: 5,
      marginBottom: 10,
      fontSize: 20,
  }
});
import React from "react";
import { Alert } from "react-native";
import  { ListItem, Button } from "react-native-elements";
import { map } from "lodash";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../utils";


export function NegociosReportadosLista(props) {
    const { filterReportes } = props;



    const removerReporte = async (id) => {
        Alert.alert("Eliminar promocion", "¿Estas seguro que deseas eliminar el reporte?",[
          {
            text: "Cancelar",
            style: "cancel",
          },
          {
            text: "Eliminar",
            onPress: async () => {
              try {
                  await deleteDoc(doc(db, "reportes" , id));
                } catch (error) {
                console.log(error);
             }
            },
          },
        ],
          { cancelable: false }
        );
       };



    return (
        map(filterReportes, (negocio, index) => {
            const data = negocio.data();
             return(
              <ListItem key={index} bottomDivider >
              <ListItem.Content>
                <ListItem.Title style={{color: "#0000FF"}} >{data.nombre}</ListItem.Title>
                <ListItem.Title>Id negocio</ListItem.Title>
                <ListItem.Subtitle>{data.id}</ListItem.Subtitle>
                <ListItem.Title>Id Usuario</ListItem.Title>
                <ListItem.Subtitle>{data.idUser}</ListItem.Subtitle>
                <ListItem.Title>{data.descripcionReporte}</ListItem.Title>
              </ListItem.Content>
              <Button title="Eliminar"
                      buttonStyle={{backgroundColor: "#FF0000"}}
                      onPress={() => {
                        removerReporte(data.id);
                      }} 
                      />
          </ListItem>
               );
            })
    );
}
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Icon, Text } from "react-native-elements";



export function UsuarioSinUbicacion(props) {
const { reload } = props;

  return (
    <View style={styles.content}>
      <Icon type='material-community' 
            name='map-marker-radius-outline'
            color="#ff850c"
            size={80} />
      <Text style={styles.text}>
        Necesitas activar la ubicacion para ver esta seccion
      </Text>
      <Button title="Tengo mi ubicacion activada"
              containerStyle={styles.btnContainer}
              buttonStyle={styles.btn}
              onPress={reload} />
    </View>
  );
};




export const styles = StyleSheet.create({
    content: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    text: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        color: "#ff850c",
        marginTop: 20,
        marginHorizontal: 10,
    },
    btnContainer: {
      marginTop: 20,
      width: "80%",
  },
  btn: {
      backgroundColor: "#ff850c",
  }
});
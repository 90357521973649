import React, { useEffect, useState } from 'react';
import { Text, ScrollView } from "react-native";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FavoritosDelMes, 
         AlimentosyBebidas, 
         ArteyCultura,
         BellezayModa,
         Farmacias,
         BaresyAntros,
         Mascotas } from "../../componentes/Home";
import { UsuarioSinUbicacion } from "../../componentes/Home/UsuarioSinUbicacion/UsuarioSinUbicacion";
import * as Location from 'expo-location';
import geohash from "ngeohash";
import { Loading } from "../../componentes/Shared/Loading/Loading";

export function RankingScreen() {
  const [userLogeado, setUserLogeado] = useState(null);
  const [hash, setHash] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onReload, setOnReload] = useState(false);
  const auth = getAuth();

  const reload = () => setOnReload((prevState) => !prevState);

  
  useEffect(() => {
   onAuthStateChanged(auth, (user) => {
         setUserLogeado(user ? true : false);
        });
    return () => {
     setUserLogeado(null);
    };
    },[]);

    

   useEffect(() => {
      if (userLogeado) {
         ( async () => {
            const location = await Location.getCurrentPositionAsync({});
             const hash = geohash.encode(location.coords.latitude, location.coords.longitude, 5);
                   setHash(hash);
                   setLoading(true);
                  }
               )();
            };
               return () => {
                  setHash(null);
                 };
            },[userLogeado, onReload]);

 if (!hash) return <UsuarioSinUbicacion reload={reload} />
 if (!hash && !loading) return <Loading show text="Cargando" />

      
  return (
       <ScrollView>
             <Text style={{ fontSize: 25,
                            fontWeight: "bold",
                            color: "#ff850c",
                            margin: 2 }}>Favoritos Usuarios</Text>
             <ScrollView horizontal={true} 
                         showsHorizontalScrollIndicator={false}
                         >
               <FavoritosDelMes hash={hash}
                                userLogeado={userLogeado}
                                 />
            </ScrollView>
            <Text style={{ fontSize: 25,
                            fontWeight: "bold",
                           color: "#ff850c",
                           margin: 2 }}>Arte y Cultura</Text>
             <ScrollView horizontal={true} 
                         showsHorizontalScrollIndicator={false}>
               <ArteyCultura hash={hash} 
                             userLogeado={userLogeado}
                              />
            </ScrollView>
            <Text style={{ fontSize: 25,
                           fontWeight: "bold",
                           color: "#ff850c",
                           margin: 2 }}>Alimentos y Bebidas</Text>
             <ScrollView horizontal={true} 
                         showsHorizontalScrollIndicator={false}>
               <AlimentosyBebidas hash={hash}
                                  userLogeado={userLogeado}
                                   />
            </ScrollView>
            <Text style={{ fontSize: 25,
                           fontWeight: "bold",
                           color: "#ff850c",
                           margin: 2 }}>Belleza y Moda</Text>
             <ScrollView horizontal={true} 
                         showsHorizontalScrollIndicator={false}>
               <BellezayModa hash={hash} 
                             userLogeado={userLogeado}   
                              />
            </ScrollView>
            <Text style={{ fontSize: 25,
                           fontWeight: "bold",
                           color: "#ff850c",
                           margin: 2 }}>Farmacias</Text>
             <ScrollView horizontal={true} 
                         showsHorizontalScrollIndicator={false}>
              <Farmacias hash={hash}
                         userLogeado={userLogeado}
                         />
            </ScrollView>
            <Text style={{ fontSize: 25,
                           fontWeight: "bold",
                           color: "#ff850c", 
                           margin: 2 }}>Bares y Antros</Text>
             <ScrollView horizontal={true} 
                         showsHorizontalScrollIndicator={false}>
               <BaresyAntros hash={hash}
                             userLogeado={userLogeado}
                              />
            </ScrollView>
            <Text style={{ fontSize: 25,
                           fontWeight: "bold",
                           color: "#ff850c",
                           margin: 2 }}>Mascotas</Text>
             <ScrollView horizontal={true} 
                         showsHorizontalScrollIndicator={false}>
               <Mascotas hash={hash}
                         userLogeado={userLogeado}
                          />
            </ScrollView>
       </ScrollView>
     );
   };
import * as Yup from "yup";

export function initialValues() {
    return {
        contrasenaActual: "",
        contrasenaNueva:"",
        repetirContrasena: "",
        };
};

export function validationSchema() {
    return Yup.object({
        contrasenaActual: Yup.string()
                             .required("Contraseña obligatoria"),
        contrasenaNueva: Yup.string()
                             .required("Nueva contraseña obligatoria"),

        repetirContrasena: Yup.string()
                              .required("Repetir contraseña obligatoria")
                               .oneOf([Yup.ref("contrasenaNueva")], "Las contraseñas no son iguales"),
    });
};
import * as Yup from "yup";

export function initialValues() {
    return {
        email: "",
        contrasena:"",
        };
};

export function validationSchema() {
    return Yup.object({
        email: Yup.string().email("Email no valido").required("El email es obligatorio"),
        contrasena: Yup.string().required("La contraseña es obligatoria"),
    });
};
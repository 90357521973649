import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    btnStyles: {
        marginTop: 30,
        borderRadius: 0,
        backgroundColor: "#fff",
        borderTopWidth: 1,
        borderTopColor: "#e3e3e3",
        borderBottomWidth: 1,
        borderBottomColor: "#e3e3e3",
        paddingVertical: 10,
    },
    btnTextStyle: {
        color: "#ff850c",
    }
});
import * as Yup from "yup";

export function initialValues() {
    return {
        email: "",
        contrasena: "",
        repetirContrasena: "",
        terminosyCondiciones: false,
        avisodePrivacidad: false,
    };
};

export function validationSchema() {
    return Yup.object({
        email: Yup.string()
                  .email("El email no es correcto")
                  .required("El correo es requerido"),
        contrasena: Yup.string()
                       .required("La contraseña es obligatoria."),
        repetirContrasena: Yup.string()
                              .required("La contraseña es obligatoria")
                              .oneOf([Yup.ref("contrasena")], "Las contraseñas tienen que ser iguales"),
        terminosyCondiciones: Yup.bool().oneOf([true], "Campo requerido"),
        avisodePrivacidad: Yup.bool().oneOf([true], "Campo requerido"),     
    });
};
import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    text: {
        color: "#00aeb7",
        textTransform: "uppercase",
        marginTop: 10,
    }
});
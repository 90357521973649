import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View, Dimensions } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { getAuth, onAuthStateChanged} from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { Carousel } from "../../../componentes/Shared/Carousel/Carousel";
import { db } from "../../../utils/firebase";
import { BtnReviewForm } from "../../../componentes/NegocioVista/BtnReviewForm/BtnReviewForm";
import { Eventos } from "../../../componentes/NegocioVista/Eventos/Eventos";    
import { Header } from "../../../componentes/NegocioVista/Header/Header";    
import { Info } from "../../../componentes/NegocioVista/Info/Info";    
import { LogoNegocio } from "../../../componentes/NegocioVista/LogoNegocio/LogoNegocio";    
import { Promociones } from "../../../componentes/NegocioVista/Promociones/Promociones";
import { Productos } from "../../../componentes/NegocioVista/Productos/Productos";    
import { Reviews } from "../../../componentes/NegocioVista/Reviews/Reviews";
import { ReportesForm } from '../../../componentes/NegocioVista/ReportesForm/ReportesForm';
import { styles} from "./Negocio.styles";



const { width } = Dimensions.get("window");

export function Negocio(props) {
    const { route } = props;
    const [logeado, setLogeado] = useState(null);
    const [negocio, setNegocio] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [onReload, setOnReload] = useState(false);
    const [botonCargar, setBotonCargar] = useState(false);
    const [visible, setVisible] = useState(false);
    const auth = getAuth();

    const reload = () => setOnReload((prevState) => !prevState);

    const toggleOverlay = () => {
      setVisible(!visible);
    };

    useEffect(() => {
         onAuthStateChanged(auth, (user) => {
         setLogeado(user ? true : false);
                 });
             return () => {
               setLogeado(null);
             };
             },[]);

  

       useEffect(() => {
           onSnapshot(doc(db, "negocios", route.params.id), (doc) => {
               setNegocio(doc.data());
           });
           return () => {
            setNegocio(null);
           };
      }, [route.params.id, logeado, onReload]);
            


   setTimeout(() => {
        setBotonCargar(true);
         }, 5000);
    

    if (negocio === undefined) return (
      <View style={{flex: 1, alignItems: "center", justifyContent: "center"}} >
        <Text style={{fontSize: 28, fontWeight: "200"}} >Negocio no existe</Text>
      </View>
    );
  
    if (!negocio) {
      return (
        <View style={{flex: 1, 
                      alignItems: "center",
                      justifyContent: "center",
                      }}>
        <ActivityIndicator  size="large" color="#ff850c" />
        <Text style={{fontSize: 15,
                      color: "#00aeb7", 
                      textTransform: "uppercase",
                      marginBottom: 10, 
                      marginTop: 5}}>Cargando</Text>
        { botonCargar && 
          <Button title='Recargar negocio'
                  buttonStyle={styles.btnRegistro}
                  onPress={() => {
                     setLoadingButton(true);
                     reload();
                   }}
                   loading={loadingButton} /> 
          }
    </View>
      );
    };

     return (
      <ScrollView contentContainerStyle={styles.container}>
         <LogoNegocio negocio={negocio} />
         <Header negocio={negocio} />
         <Promociones negocio={negocio} />
         <Info negocio={negocio} />
         <Text style={{ fontSize: 28,
                         alignSelf: "center",
                         fontWeight: "bold",
                         color: "#00aeb7",
                         marginTop: 10
                          }} >Imagenes</Text>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }} >
               <Carousel arrayImages={negocio.imagenes} />
             </View>
             <Productos negocio={negocio} />
             <Eventos negocio={negocio} />
             <Text style={{alignSelf: "center", fontSize: 28, fontWeight: "bold", color: "#00aeb7", marginTop: 20}}>
                    Comentarios
             </Text>           
      <BtnReviewForm negocio={negocio} />
      <Reviews idNegocio={route.params.id} />
      <Text style={{ color: "#FF0000", 
                        fontSize: 18, 
                        fontWeight: "500", 
                        alignSelf: "center",
                        marginTop: 25,
                        marginBottom: 15,
                        }}
               onPress={() => {
                    setVisible(true);
                     }} >Reportar Negocio</Text>
      <ReportesForm idUser={negocio.idUser}
                  nombreNegocio={negocio.nombre}
                  visible={visible}
                  setVisible={setVisible}
                  toggleOverlay={toggleOverlay}

              />
      </ScrollView>
    );
};

import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        marginTop: 5,
    },
    contentSinPromociones: {
        alignItems: "center",
        margin: 10,
    },
    textSinPromocion: {
       fontSize: 15,
       color: "#ff0000",
    },
    titulo: {
        fontSize: 28,
        alignSelf: "center",
        fontWeight: "bold",
        color: "#00aeb7",
        marginBottom: 5
    },
    btnAgregarPromocion: {
        alignSelf: "center",
        margin: 5,
    }
});
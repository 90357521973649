import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        backgroundColor: "#fff",
        marginVertical: 10,
        marginHorizontal: 10,
        width: 350,
        height: 300,
    },
    imagen: {
        width: "100%",
        height: 250,
    },
    infoContent: {
        paddingHorizontal: 20,
        paddingVertical: 15,
    },
    nombre: { 
        fontSize: 20,
        fontWeight: "bold",
    },
    icon: {
      position: "absolute",
      backgroundColor: "#fff",
      borderRadius: 50,
      padding: 15,
      top: -30,
      right: 20,
    },
});
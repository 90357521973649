import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        paddingVertical: 30,
    },
    avatar: {
        marginRight: 20,
        backgroundColor: "orange",
    },
    displayName: {
        fontWeight: "bold",
        marginBottom: 5,
    }
});
import React, { useState } from 'react'
import { View } from 'react-native'
import { Input, Icon, Button, CheckBox, Text } from "react-native-elements";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigation } from "@react-navigation/native";
import { doc, setDoc } from "firebase/firestore"; 
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./DatosRegistro";
import { screenName } from "../../../utils/screenName";
import { db } from "../../../utils/firebase";
import { styles } from "./RegistroForm.styles";

export function RegistroForm() {
 const [mostrarContrasena, setMostrarContrasena] = useState();
 const [showRepeatPassword, setShowRepeatPassword] = useState();
 const [terminosyCondiciones, setTerminosyCondiciones] = useState(false);
 const [avisodePrivacidad, setAvisodePrivacidad] = useState(false);
 const navegacion = useNavigation();

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        console.log(formValue);
            const auth = getAuth();
            const createdAt = new Date();
            const { user } = await createUserWithEmailAndPassword(auth,
                                           formValue.email,
                                           formValue.contrasena,
                                           )
            await setDoc(doc(db, "usuarios", user.uid), { 
                   id: user.uid,   
                   correo: formValue.email,
                   fecha: createdAt, 
                      });                     
            navegacion.navigate(screenName.cuenta.cuenta)
      } catch (error) {
        console.log(error);
        alert("No se ha podido registrar el usuario, intente mas tarde.")
      }
    }
  });


  const esconderContrasena = () =>{
       setMostrarContrasena((prevState) => !prevState )
  };

  const esconderContrasenaRepetida = () =>{
    setShowRepeatPassword((prevState) => !prevState )  
 };


  return (
    <View style={styles.content}>
      <Input placeholder='Correo electronico'
        containerStyle={styles.input}
        rightIcon={<Icon type='material-community'
          name='at'
          iconStyle={styles.icon} />}
        onChangeText={(text) => formik.setFieldValue("email", text)}
        errorMessage={formik.errors.email}
      />
      <Input placeholder='Contraseña'
        containerStyle={styles.input}
        secureTextEntry={mostrarContrasena ? false : true}
        rightIcon={<Icon type='material-community'
                         name={mostrarContrasena ? "eye-off-outline" : "eye-outline"}
                         iconStyle={styles.icon}
                         onPress={esconderContrasena} />}
        onChangeText={(text) => formik.setFieldValue("contrasena", text)}
        errorMessage={formik.errors.contrasena}
      />
      <Input placeholder='Confirmar contraseña'
        containerStyle={styles.input}
        secureTextEntry={showRepeatPassword ? false : true}
        rightIcon={<Icon type='material-community'
                         name={showRepeatPassword ? "eye-off-outline" : "eye-outline"}
                         iconStyle={styles.icon}
                         onPress={esconderContrasenaRepetida} />}
        onChangeText={(text) => formik.setFieldValue("repetirContrasena", text) }
        errorMessage={formik.errors.repetirContrasena}
      />
      <CheckBox
      title="Acepto terminos y condiciones"
      checked={terminosyCondiciones}
      uncheckedColor='#ff0000'
      checkedColor='#008000'
      checkedTitle='Listo'
      onPress={() => {
        setTerminosyCondiciones(!terminosyCondiciones);
        formik.setFieldValue("terminosyCondiciones", !terminosyCondiciones);
      }}
      />
      <Text style={{color:"#ff0000", fontSize: 12}}>
          {formik.errors.terminosyCondiciones}        
      </Text>
      <Text style={{ color: "#0000ff", 
                    textDecorationLine: "underline", 
                    fontSize: 15,
                    marginBottom: 10}}
            onPress={ () => {
              navegacion.navigate(screenName.cuenta.terminosyCondiciones)
            }}
                 > Terminos y Condiciones
      </Text>

      <CheckBox
      title="Aviso de Privacidad"
      checked={avisodePrivacidad}
      uncheckedColor='#ff0000'
      checkedColor='#008000'
      checkedTitle='Listo'
      onPress={() => {
        setAvisodePrivacidad(!avisodePrivacidad);
        formik.setFieldValue("avisodePrivacidad", !avisodePrivacidad );
      }}
      />
      <Text style={{color:"#ff0000", fontSize: 12}}>
          {formik.errors.avisodePrivacidad}        
      </Text>
      <Text style={{ color: "#0000ff", 
                    textDecorationLine: "underline", fontSize: 15}}
            onPress={() => {
              navegacion.navigate(screenName.cuenta.avisodePrivacidad)
                            }}
                    >Aviso de Privacidad
      </Text>

      <Button title="Unirse"
        containerStyle={styles.btnContainer}
        buttonStyle={styles.btn}
        onPress={formik.handleSubmit}
        loading={formik.isSubmitting} />
    </View>
  );
};

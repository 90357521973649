import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    container: {
        backgroundColor: "#fff",
    },
    btnRegistro: {
        color: "white",
        backgroundColor: "#ff850c",
        fontWeight: "bold",
        borderRadius: 10,
        padding: 10,
        marginTop: 5,
        marginBottom: 10,
        fontSize: 20,
    }
});
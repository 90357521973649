const homeStack = {
    tab: "HomeTab",
    home: "Home",
};

const promocionesStack = {
    tab: "PromocionesTab",
    promociones: "Promociones",
    negocio: "Negocio",
    agregarReview: "AgregarReview",
};

const favoritosStack = {
    tab: "FavoritosTab",
    favoritos: "Favoritos",
};

const cuentaStack = {
    tab: "CuentaTab",
    cuenta: "Cuenta",
    login: "Login",
    registro: "Registro",
    crearNegocio: "CrearNegocio",
    editarNegocio: "EditarNegocio",
    restablecerContrasena: "Restablecer Contraseña",
    terminosyCondiciones: "Terminos y Condiciones",
    avisodePrivacidad: "Aviso de Privacidad",
    usuariosIlimitados: "UsuariosIlimitados",
    usuariosTodos: "UsuariosTodos",
    negociosReportados: "NegociosReportados"
};

export const screenName = {
    home: homeStack,
    promociones: promocionesStack,
    favoritos: favoritosStack,
    cuenta: cuentaStack,
};
import React, { useState } from 'react';
import { View } from 'react-native';
import { Input, Icon, Button } from "react-native-elements";
import { useFormik } from "formik";
import { initialValues ,validationSchema } from "./DatosIniciarSesionForm"
import { styles } from "./IniciarSesionForm.styles";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigation } from "@react-navigation/native";
import { screenName } from '../../../utils/screenName';

export function IniciarSesionForm() {
const [mostrarContrasena, setMostrarContrasena] = useState(null);
const navegacion = useNavigation();

const ocultarContrasena = () => {
      setMostrarContrasena((prevState) => !prevState );
};

const formik = useFormik({
  initialValues: initialValues(),
  validationSchema: validationSchema(),
  validateOnChange: false,

  onSubmit: async (formValue) => {
     try {
        const auth = getAuth();
       await signInWithEmailAndPassword(auth, 
                                  formValue.email,
                                  formValue.contrasena,
                                  );
          navegacion.navigate(screenName.cuenta.cuenta);
     } catch (error) {
     alert("Correo electronico o contraseña incorrecta.")
     } 
  }
});



  return (
    <View style={styles.content}>
        <Input placeholder='Correo electronico' 
               containerStyle={styles.input}
               rightIcon={<Icon type='material-community'
                                name='at'
                                iconStyle={styles.icon} />}
               onChangeText={(text) => formik.setFieldValue("email", text)}
               errorMessage={formik.errors.email}
               />

        <Input placeholder='Contraseña' 
               containerStyle={styles.input}
               secureTextEntry={mostrarContrasena ? false: true}
               rightIcon={<Icon type='material-community'
                                 name={mostrarContrasena ? "eye-off-outline" : "eye-outline"}
                                 iconStyle={styles.icon}
                                 onPress={ocultarContrasena} />}
                 onChangeText={(text) => formik.setFieldValue("contrasena", text)} 
                errorMessage={formik.errors.contrasena}
             />

        <Button title="Iniciar sesion" 
                containerStyle={styles.btnContainer} 
                buttonStyle={styles.btn} 
                onPress={formik.handleSubmit}
                loading={formik.isSubmitting}
             />
    </View>
  );
};

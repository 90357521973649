import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {AirbnbRating, Avatar, ListItem, Text } from 'react-native-elements';
import { collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import { map } from "lodash";
import { db } from '../../../utils/firebase';
import { Loading } from '../../Shared';
import { styles } from "./Reviews.styles";

export function Reviews(props) {
    const { idNegocio } = props; 
    const [reviews, setReviews] =  useState(null);

useEffect(() => {
    const q = query(
        collection(db, "reviews"),
        where("idNegocio", "==", idNegocio),
        orderBy("createdAt", "desc")
          );
         onSnapshot(q, (snapshot) => {
           setReviews(snapshot.docs);
            });
           return () => {
              setReviews(null);
            };
        },[]);

    if (!reviews) return <Loading show text="Cargando" />
  

  return (
    <View style={styles.content}>
        {map(reviews, (review) => {
         const data = review.data();
           const date = new Date().toLocaleDateString();
            return (
                 <ListItem key={data.id} bottomDivider containerStyle={styles.review}>
                    <Avatar source={{ uri: data.avatar}} size={70} rounded />
                    <ListItem.Content>
                       <View style={styles.info}>
                          <Text style={styles.comentario}>{data.comentario}</Text>
                          <View style={styles.contentRatingDate}>
                            <AirbnbRating 
                             defaultRating={data.rating}
                             showRating={false}
                             size={25}
                             isDisabled
                             starContainerStyle={styles.starContainer}
                            />
                           <Text style={styles.date}>{date}</Text>
                          </View>
                       </View>
                    </ListItem.Content>
                </ListItem>
            );
          }   
        )}
    </View>
  );
};

import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
       margin: 5,
    },
    boton: {
        backgroundColor: "transparent",
        marginBottom: 10,
    },
    btnText: {
       color: "#ff850c",
       fontWeight: "bold",
    },
    text: {
        fontSize: 15,
        marginTop: 15,
        marginHorizontal: 20,
        color: "#ff0000",
    },
    textClick: {
        color: "#ff850c",
        fontWeight: "bold",
        fontSize: 20,
        marginTop: 5,
        marginBottom: 15,
    },
    textSendReview: {
    textAlign: 'center',
    color: "#00a680",
    }
});
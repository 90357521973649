import React, { useState } from 'react';
import { View } from 'react-native';
import { ListItem, Icon } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { Modal } from '../Shared/Modal/Modal';
import { CambiarNombreForm } from './CambiarNombreForm/CambiarNombreForm';
import { CambiarEmailForm } from "./CambiarEmailForm/CambiarEmailForm";
import { CambiarContrasenaForm } from "./CambiarContrasenaForm/CambiarContrasenaForm";
import { PagoMensual } from "./ListaNegocios/PagoMensual";
import { map } from "lodash";

export function OpcionesCuenta(props) {
 const { onReload, negocios, email, nombre } = props;
 const [showModal, setShowModal] = useState(false);
 const [renderComponent, setRenderComponent] = useState(null);

 const navegacion = useNavigation();

 const cerrarModal = () => setShowModal((prevState) => !prevState);
  
   const selectComponent = (key) => {
       if (key === "displayName") {
          setRenderComponent(<CambiarNombreForm close={cerrarModal} onReload={onReload} />);
       }

       if (key === "email") {
        setRenderComponent(<CambiarEmailForm close={cerrarModal} onReload={onReload} />);
       }

       if (key === "contrasena") {
        setRenderComponent(<CambiarContrasenaForm close={cerrarModal} />);
       }
    
       if (key === "listanegocios") {
        setRenderComponent( <PagoMensual close={cerrarModal} 
                                         negocios={negocios}
                                         email={email}
                                         nombre={nombre}
                          />
                         );
       }
       cerrarModal();
   };

    const menuOpciones = getMenuOpciones(selectComponent);

  
    return (
    <View>
        {map(menuOpciones, (menu, index) => (
             <ListItem key={index} 
                       bottomDivider 
                       onPress={menu.onPress}>
              <Icon type={menu.iconType} 
                    name={menu.iconNameLeft} 
                    color={menu.iconColorLeft} />           
                <ListItem.Content>
                    <ListItem.Title>{menu.title}</ListItem.Title>
                </ListItem.Content>
              <Icon type={menu.iconType} 
                    name={menu.iconNameRight} 
                    color={menu.iconColorRight} />
             </ListItem>
        ) )}
        <Modal show={showModal} close={cerrarModal}>{renderComponent}</Modal>
    </View>
  );


  function getMenuOpciones(selectComponent) {
    return [
        {
            title: "Cambiar nombre y apellido",
            iconType: "material-community",
            iconNameLeft: "account-circle",
            iconColorLeft: "#ccc",
            iconNameRight: "chevron-right",
            iconColorRight: "#ccc",
            onPress: () => selectComponent("displayName"),
        },
        {
            title: "Cambiar email",
            iconType: "material-community",
            iconNameLeft: "at",
            iconColorLeft: "#ccc",
            iconNameRight: "chevron-right",
            iconColorRight: "#ccc",
            onPress: () => selectComponent("email"),
        },
        {
            title: "Cambiar contraseña",
            iconType: "material-community",
            iconNameLeft: "lock-reset",
            iconColorLeft: "#ccc",
            iconNameRight: "chevron-right",
            iconColorRight: "#ccc",
            onPress: () => selectComponent("contrasena"),
        },
        {  
            title: "Suscribete",
            iconType: "material-community",
            iconNameLeft: "store",
            iconColorLeft: "#ccc",
            iconNameRight: "chevron-right",
            iconColorRight: "#ccc",
            onPress: () => selectComponent("listanegocios"),
        },
    ];
};

};


import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { RankingScreen } from "../screens/ranking/RankingScreen";
import { screenName } from "../utils/screenName";


const Stack = createNativeStackNavigator();

export function RankingStack() {

    return(
        <Stack.Navigator>
            <Stack.Screen 
            name={screenName.home.home}
            component={RankingScreen}
            options={{title: "Ranking"}}
         />
        </Stack.Navigator>
    );
}
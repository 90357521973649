import React, { useEffect, useState } from "react";
import { StyleSheet, ScrollView, ActivityIndicator, View } from "react-native";
import { Input, Button, SearchBar, Text } from "react-native-elements";
import { collection, onSnapshot, query } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import { doc, setDoc } from "firebase/firestore";
import { initialValues, validationSchema } from "./UsuariosIlimitados.Data";
import { db } from "../../../utils/firebase";
import { UsuariosIlimitadosLista } from "../../../componentes/Cuenta/UsuariosIlimitados/UsuariosIlimitadosLista";


export function UsuariosIlimitadosScreen() {
  const [usuariosIlimitados, setUsuariosIlimitados] = useState(null);
  const [filterUsuarios, setFilterUsuarios] = useState(usuariosIlimitados);
  const [onReload, setOnReload] = useState(false);
  const [texto, setTexto] = useState("");

 const reload = () => setOnReload((prevState) => !prevState);
 
 console.log(usuariosIlimitados);
  useEffect(() => {
    const q = query(
        collection(db, "usuariosilimitados"),
            );
        onSnapshot(q, (snapshot) => {
        setUsuariosIlimitados(snapshot.docs);
        setFilterUsuarios(snapshot.docs);
         });
         return () => {
          setUsuariosIlimitados(null);
          setFilterUsuarios(usuariosIlimitados);
               };
         }, [onReload]);
         
   
   
          const searchFilterPromociones = (text) => {
                  if (text) {
                    const newData = usuariosIlimitados.filter((item) => {
                    const empresa = item.data();
                    const nombreEmpresa = empresa.nombreEmpresa
                    const correo = empresa.correo
                    const search = correo + nombreEmpresa
                    const itemData = search ? 
                                     search.toUpperCase() : ''.toUpperCase();
                    const textData = text.toUpperCase();
                    return itemData.indexOf(textData) > -1;                               
                        });
                        setFilterUsuarios(newData);
                        setTexto(text);
               }  else {
                 setFilterUsuarios(usuariosIlimitados);
                 setTexto(text);
                  }
                }
           



    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            console.log(formValue);
          try {
            const auth = getAuth();
            const createdAt = new Date();
            const { user } = await createUserWithEmailAndPassword(auth,
                                           formValue.email,
                                           formValue.contrasena,
                                           )
            await setDoc(doc(db, "usuariosilimitados", user.uid), {
                 nombreEmpresa: formValue.nombreEmpresa,
                 correo: formValue.email,
                 id: user.uid,
                 fecha: createdAt,
            });
            reload();
          } catch (error) {
            console.log(error);
            alert("No se ha podido registrar el usuario, intente mas tarde.")
          }
        }
      });


      if (!usuariosIlimitados) {
        return (
          <View style={{flex: 1, 
                        alignItems: "center",
                        justifyContent: "center",
                        }}>
          <ActivityIndicator  size="large" color="#ff850c" />
          <Text style={{fontSize: 15,
                        color: "#00aeb7", 
                        textTransform: "uppercase",
                        marginBottom: 10, 
                        marginTop: 5}}>Cargando</Text>
      </View>
        );
      };



    return(
    <ScrollView >
          <Input placeholder='Nombre empresa'
                 containerStyle={styles.input}
                 onChangeText={(text) => formik.setFieldValue("nombreEmpresa", text)}
                 errorMessage={formik.errors.nombreEmpresa}
            />
      <Input placeholder='Correo electronico'
             containerStyle={styles.input}
             onChangeText={(text) => formik.setFieldValue("email", text)}
             errorMessage={formik.errors.email}
            />
      <Input placeholder='Contraseña'
             containerStyle={styles.input}
             
             onChangeText={(text) => formik.setFieldValue("contrasena", text)}
             errorMessage={formik.errors.contrasena}
             />
      <Input placeholder='Confirmar contraseña'
             containerStyle={styles.input}
             
             onChangeText={(text) => formik.setFieldValue("repetirContrasena", text) }
             errorMessage={formik.errors.repetirContrasena}
             />
       <Button title="Crear Usuario"
               buttonStyle={styles.btn}
               onPress={formik.handleSubmit}
               loading={formik.isSubmitting} />
       <SearchBar 
           placeholder='Busca la empresa'
           platform="default"
           value={texto}
          placeholderTextColor={"#00aeb7"}
          containerStyle={{backgroundColor: "#00aeb7", marginTop: 5}}
          inputContainerStyle={{backgroundColor: "white"}}
          onChangeText={(text) => searchFilterPromociones(text)}
        />
        <UsuariosIlimitadosLista filterUsuarios={filterUsuarios}
                                  />
        </ScrollView>
    )
}


export const styles = StyleSheet.create({
    content: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
    }, 
    input: {
        width: "100%",
        marginTop: 20,
    },
    icon: {
        color: "#c1c1c1",
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});
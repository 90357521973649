import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HomeScreen } from "../screens/home/HomeScreen";
import { Negocio } from "../screens/home/Negocio/Negocio";
import { AgregarReview } from "../screens/home/Negocio/AgregarReview/AgregarReview";
import { screenName } from "../utils/screenName";


const Stack = createNativeStackNavigator();

export function HomeStack() {

    return(
        <Stack.Navigator>
            <Stack.Screen 
            name={screenName.promociones.promociones}
            component={HomeScreen}
            options={{title: "Promociones"}}
         />
            <Stack.Screen 
            name={screenName.promociones.negocio}
            component={Negocio}
            options={{title: "Promociones"}}
         />
            <Stack.Screen 
            name={screenName.promociones.agregarReview}
            component={AgregarReview}
            options={{title: "Promociones"}}
         />
        </Stack.Navigator>
    );
}
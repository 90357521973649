import React from 'react';
import { ListItem, Icon, Image } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { map, size } from "lodash";
import { screenName } from '../../../../utils';



export function NegociosConUbicacion(props) {
  const { promociones, filterDataTodos, filterData} = props;
  const navegacion = useNavigation();


  const irNegocio = (id) => {
    navegacion.navigate(screenName.promociones.negocio,  { 
      id: id,
     });
};

if (size(promociones) === 0) {
        return(
            map(filterDataTodos, (item, index) => {
              const promocion = item.data();
               return(
                <ListItem key={index} bottomDivider onPress={() => irNegocio(promocion.idNegocio)} >
                <Image source={{uri: promocion.promociones[0].imgPromocion}} style={{ width: 70, height: 70}} />
                <ListItem.Content>
                  <ListItem.Title>{promocion.nombre}</ListItem.Title>
                  <ListItem.Subtitle style={{color: "#00aeb7"}}>{promocion.promociones[0].descripcionPromocion}</ListItem.Subtitle>
                </ListItem.Content>
                <Icon type='material-community' name='chevron-right'  />
            </ListItem>
                 );
              })
          );
       } else {
        return(
            map(filterData, (item, index) => {
              const promocion = item.data();
            return(
             <ListItem key={index} bottomDivider onPress={() => irNegocio(promocion.idNegocio)} >
             <Image source={{uri: promocion.promociones[0].imgPromocion}} style={{ width: 70, height: 70}} />
              <ListItem.Content>
               <ListItem.Title>{promocion.nombre}</ListItem.Title>
               <ListItem.Subtitle style={{color: "#00aeb7"}}>{promocion.promociones[0].descripcionPromocion}</ListItem.Subtitle>
             </ListItem.Content>
             <Icon type='material-community' name='chevron-right'  />
            </ListItem>
                );
              })
       );
      };
    };
 
import { StyleSheet } from "react-native";


export const styles = StyleSheet.create({
    content: {
        alignItems: "center",
        width: "95%",
        paddingVertival: 10,
    },
    input: {
        marginBottom: 10,
    },
    btnContainer: {
        width: "90%",
    },
    btn: {
        backgroundColor: "#ff850c",
    }
});

import React from 'react';
import { View, ScrollView } from "react-native";
import { Text, Image } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { getAuth } from "firebase/auth";
import { IniciarSesionForm } from "../../../componentes/Auth";
import { screenName } from "../../../utils/screenName";
import { styles } from "./IniciarSesion.styles";

export function IniciarSesion() {
  const navegacion = useNavigation();
  const auth = getAuth();

  const IrRegistro = () => {
    navegacion.navigate( screenName.cuenta.registro)
  };

  const restablacerContraseña = () => {
       navegacion.navigate(screenName.cuenta.restablecerContrasena);
  };
  
  return (
    <ScrollView>
      <View style={styles.content}>
        <Image source={require("../../../../assets/img/finddin-logo.png")} 
               style={{resizeMode: "contain", width: "100%", height: 100}} />
      <IniciarSesionForm />
      <Text 
        style={{ textAlign: "center", marginTop: 15, fontSize: 16, color: "red", fontWeight: "bold" }}
        onPress={restablacerContraseña}
        > 
        ¿Olvidaste tu contraseña?
      </Text>
      <Text style={styles.title}>Ingresa a tu perfil de Finddin</Text>
      <Text style={styles.description}>
              Recibe las promociones de tus negocios favoritos,
              comenta y crea tu directorio personal.
       </Text>   
       <View style={{alignItems: "center"}}>
       <Text style={styles.textRegistro}>Aun no estas registrado?</Text>
       <Text style={styles.btnRegistro} onPress={IrRegistro}>Registrate</Text>
       </View>
    </View>
  </ScrollView>
  );
};


import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Text, Image } from "react-native-elements";
import { getAuth, onAuthStateChanged} from "firebase/auth";
import { query, collection, deleteDoc, doc, where, onSnapshot, orderBy} from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { map, size } from 'lodash';
import { styles } from './Eventos.styles';


export function Eventos(props) {
   const { negocio } = props;
   const [logeado, setLogeado] = useState(null);
   const [eventos, setEventos] = useState(null);
   const auth = getAuth();

   const date = new Date();

   useEffect(() => {
     onAuthStateChanged(auth, (user) => {
       setLogeado(user);
            });
        return () => {
         setLogeado(null);
          }    
           },[]);

   useEffect(() =>{
      const q = query(
                collection(db, "eventos"),
                where("idNegocio", "==", negocio.id),
                where("fechaTerminaEvento", ">=", date),
                orderBy("fechaTerminaEvento", "asc")
                );
               onSnapshot(q, (snapshot) => {
                    setEventos(snapshot.docs);
               })
               return () => {
                    setEventos(null);
               };
            },[]); 


 return (
  <View style={{alignItems: "center"}}>
     <Text style={{ marginTop: 20,
                     marginBottom: 10,
                     fontSize: 28,
                     alignSelf: "center", 
                     fontWeight: "bold",
                     color: "#00aeb7",}}>Eventos
     </Text>
    <View style={{flexDirection: "row", flexWrap: "wrap"}} >
    {size(eventos) === 0 ? (
      <View>
        <Text style={{color: "orange", marginTop: 10}}>No hay eventos registrados</Text>
      </View>
    ) : (
      map(eventos, (item, index) => {
        const evento = item.data()
      return (
         <View key={index} 
                     horizontal={true}
                     style={styles.eventoContainer} >
           <Image style={styles.imagenEvento} 
                 source={{uri: evento.eventoImagenes[0]}} />
           <Text style={styles.descripcion}>{evento.descripcionEvento}</Text>
         </View>
           );
       })
    )}
    </View>                   
    </View>
 );
};




